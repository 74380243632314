import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import withAuth from '../users/withAuth';
import axios from 'axios';

import '../styles/userInfo.css';

function UserInfo() {

  const emailId = localStorage.getItem('email');
  const accesstoken = localStorage.getItem('accesstoken');

  const [userDetails, setUserDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    firstName: userDetails.firstName || '',
    lastName: userDetails.lastName || '',
    address: userDetails.address || '',
    country: userDetails.country || '',
    contact: userDetails.contact || '',
  });

  const [activeFields, setActiveFields] = useState({
    firstName: false,
    lastName: false,
    address: false,
    country: false,
    contact: false,
  });


  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(`https://tech.cle.org.pk:8085/userdetails/${emailId}`);
        // const response = await axios.post(`http://localhost:8085/userdetails/${emailId}`);
        setUserDetails(response.data);
        setFormData({
          firstName: response.data.firstName || '',
          lastName: response.data.lastName || '',
          address: response.data.address || '',
          country: response.data.country || '',
          contact: response.data.contact || '',
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, [emailId]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputFocus = (name) => {
    setActiveFields((prevActiveFields) => ({
      ...prevActiveFields,
      [name]: true,
    }));
  };

  const handleInputBlur = (name) => {
    setActiveFields((prevActiveFields) => ({
      ...prevActiveFields,
      [name]: false,
    }));
  };

  const requestData = {
    accessToken: accesstoken,
    ...formData,
  };


  const handleSaveChanges = async () => {
    try {
      localStorage.setItem('firstName', formData.firstName);
      const response = await axios.post(`https://tech.cle.org.pk:8085/update_user`, requestData);
      // const response = await axios.post(`http://localhost:8085/update_user`, requestData);
      if (response.ok) 
      {
        localStorage.setItem('firstName', formData.firstName);

      } else {
        setErrorMessage("Update failed. Please Try again.");
        console.error('Server update failed:', response.data);
      }
    } catch (error) {
      setErrorMessage("Update failed. Please Tech and User services.");
      console.error('Error updating user:', error);
    }
  };

  return (
    <div className="first-box-user-info p-2" style={{ paddingTop: '5%' }}>
      <Helmet>
        <title>User Profile</title>
      </Helmet>

      <div className="m-4 user-info-container-well" align="center">
        <div className="alert alert-info">
          <a className="close" data-dismiss="alert">
            ×
          </a>
          <text>You can change your personal information here.</text>
        </div>

        { errorMessage && <p className='login-error-clr '>{errorMessage}</p>}

        <form>
          <div className="form-group">
            <label className={`col-lg-3 user-info-page ${activeFields.firstName ? 'label-active' : ''}`}> First Name </label>
            <div className="col-md-4">
              <input
                id="user-info-input"
                className={`form-control ${
                  activeFields.firstName ? 'user-info-input-active' : 'user-info-input-default'
                }`}
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus('firstName')}
                onBlur={() => handleInputBlur('firstName')}
              />
            </div>
          </div>

          <div style={{marginTop:'-3%'}}></div>

          <div className="form-group">
            <label className={`col-lg-3 user-info-page ${activeFields.lastName ? 'label-active' : ''}`}>  Last Name </label>
            <div className="col-md-4">
              <input
                id="user-info-input"
                className={`form-control ${
                  activeFields.lastName ? 'user-info-input-active' : 'user-info-input-default'
                }`}
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus('lastName')}
                onBlur={() => handleInputBlur('lastName')}
              />
            </div>
          </div>

          <div style={{marginTop:'-3%'}}></div>

          <div className="form-group">
            <label className={`col-lg-3 user-info-page ${activeFields.address ? 'label-active' : ''}`}> Address </label>
            <div className="col-md-4">
              <input
                id="user-info-input"
                className={`form-control ${
                  activeFields.address ? 'user-info-input-active' : 'user-info-input-default'
                }`}
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus('address')}
                onBlur={() => handleInputBlur('address')}
              />
            </div>
          </div>

          <div style={{marginTop:'-3%'}}></div>

          <div className="form-group">
            <label className={`col-lg-3 user-info-page ${activeFields.country ? 'label-active' : ''}`}> Country </label>
            <div className="col-md-4">
              <input
                id="user-info-input"
                className={`form-control ${
                  activeFields.country ? 'user-info-input-active' : 'user-info-input-default'
                }`}
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus('country')}
                onBlur={() => handleInputBlur('country')}
              />
            </div>
          </div>

          <div style={{marginTop:'-3%'}}></div>
          
          <div className="form-group">
            <label className={`col-lg-3 user-info-page ${activeFields.contact ? 'label-active' : ''}`}> Contact Number </label>
            <div className="col-md-4">
              <input
                id="user-info-input"
                className={`form-control ${
                  activeFields.contact ? 'user-info-input-active' : 'user-info-input-default'
                }`}
                type="text"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                onFocus={() => handleInputFocus('contact')}
                onBlur={() => handleInputBlur('contact')}
              />
            </div>
          </div>

          <button className="btn btn-primary user-info-button" onClick={handleSaveChanges}> Save Changes </button>
        </form>
      </div>
    </div>
  );
}

const UserInfoWithAuth = withAuth(UserInfo);
export default UserInfoWithAuth;

import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_tts.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function text_to_speech() {


    return (

    <div id="content-tts"> 

        <Helmet>
            <title>CLE Text-Of-Speech (TTS) Service</title>
        </Helmet>

        <div className="row tts-banner">
                <div className="tts-banner-title">PUNJABI TEXT-TO-SPEECH (TTS) SERVICE </div>
        </div>


        <div className="row tts-service-row">
            <div className="col-lg-8">
                <div className="row">
                    <div className="tts-service-text">
                        <p>Punjabi TTS service enables the computer to read out Punjabi content available in digital forms e.g. emails, websites and documents. This technology can be leveraged to provide information to people who are blind or illiterate population who cannot read or write, through more conventional audio means like telephone, public address systems etc. without human interaction.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="tts-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_ptts">
                    <div className="tts-api-ref">
                        <img src={tts_api} alt='tts api'/>
                        <div className="tts-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>
      
    )
}

import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_langid.css';
import '../styles/font_family.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function LanguageID() {

  return (


    <div id="content-langid"> 

        <Helmet>
            <title>CLE Language Identification Service</title>
        </Helmet>

        <div className="row langid-banner">
                <div className="langid-banner-title">URDU LANGUAGE IDENTIFICATION SERVICE </div>
        </div>


        <div className="row langid-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="langid-service-text">
                        <p>CLE Urdu Language Identification Service identifies the presence of Urdu in multilingual content having Arabic, English and Urdu text and computes the proportion of Urdu present in the input text. This service returns a decimal score corrected to two decimal places, computed on the basis of proportion of Urdu text. The score lies between 0 and 1.The more closer the score to 1 the more Urdu words in the input text.</p>
                    </div>
                </div>
                
                <div className="row">
                    <a href="/register" className="langid-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_langid">
                    <div className="langid-api-ref">
                        <img src={tts_api} alt='langid api'/>
                        <div className="langid-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}

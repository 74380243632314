import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import withAuth from '../users/withAuth';

import '../styles/admin_service.css';

function NewCostingModel(props) {

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newServiceInput, setNewServiceInput] = useState({});

    const { show, onClose, accessToken } = props;
    const [serviceList, setServiceList] = useState([]);



    const handleCloseModal = () => {
        setNewServiceInput({});
        props.onClose();
        setShowModal(false);
        window.location.reload();
    };



    const fetchServiceData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/service_detail', {});
        // const response = await axios.post('http://localhost:8085/service_detail', {});
        setServiceList(response.data); 
      } catch (error) {
        setErrorMessage('Error while fetching the data.');
        console.error('Error fetching services data failed. :', error);
      }
    };
    

    useEffect(() => {
      fetchServiceData();
    }, []);



    const handleAddService = async () => {
      newServiceInput.serviceTitle = accessToken;

        try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/new_service', newServiceInput);
        // const response = await axios.post('http://localhost:8085/new_user_service', newServiceInput);

        if (response.status === 200) 
        {
            setShowModal(false);
            window.location.reload();
        } else {
            setErrorMessage('Failed to add the new service. Please try again.');
        }
        } catch (error) {
        setErrorMessage('An error occurred while adding the service. Please try again.');
        }
    };

  return (
    <Modal show={props.show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className='btn-font'>New User Costing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <div className="service-form-properties">

      <div className="form-group services-form-group">
        <label className="service-form-label">Service <span className="required-fields">*</span> </label>
        <select
          className="form-control service-form-box"
          value={newServiceInput.serviceName || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, serviceName: e.target.value })}
        >
          <option value="">Select a service</option>
          {serviceList.map((service) => (
            <option key={service.id} value={service.serviceName}>
              {service.serviceName}
            </option>
          ))}
        </select>
      </div>


      <div className="form-group services-form-group">
        <label className="service-form-label">Unit Cost <span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.unit_cost || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, unit_cost: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label"> Service Discount(%) <span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          value={newServiceInput.discount || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, discount: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Minimum Units <span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          value={newServiceInput.min_unit || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, min_unit: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Minimum Charge <span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          value={newServiceInput.min_charge || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, min_charge: e.target.value })}
        />
      </div>
   </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-danger btn-font" onClick={handleCloseModal}> Cancel </Button>
        <Button variant="primary" className="btn btn-success btn-font" onClick={handleAddService}> Add User Costing </Button>
      </Modal.Footer>
    </Modal>
  );
}


// export default NewCostingModel;
const NewCostingWithAuth = withAuth(NewCostingModel);
export default NewCostingWithAuth;

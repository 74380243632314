import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useLocation } from 'react-router-dom';
import withAuth from '../users/withAuth';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

import '../styles/admin_service.css';
import NewCostingWithAuth from './NewCostingModel';


function ViewCostingModel() {

    const [currentDisplayedService, setCurrentDisplayedService] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [inputValues, setInputValues] = useState({});
    const [inputChanged, setInputChanged] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);

    const { emailId } = useParams();
    const location = useLocation();
    const accessToken = location.state.accessToken;


    const handleShowAddServiceModal = () => {
      setShowAddServiceModal(true);
    };

    const handleCloseAddServiceModal = () => {
      setShowAddServiceModal(false);
    };


    const handleShowModal = (service) => {
      setSelectedService(service);
      setShowModal(true);
    };
    
  
    const handleCloseModal = () => {
      setShowModal(false);
      window.location.reload();
    };


    const handleInputChange = (e, field) => {
      const { value } = e.target;
      setInputValues({
          ...inputValues,
          [field]: value,
      });
      setInputChanged({
          ...inputChanged,
          [field]: true,
      });
  };






  const handleUpdateUnitCost = async (userService) => { 
    try {
      const updateData = { userService: userService, accessToken:accessToken, userUnitCost: inputValues['userUnitCost'] };   
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_user_unitCost', updateData);
      // const response = await axios.post('http://localhost:8085/update_user_unitCost', updateData);
      if (response.status === 200) 
      {
        console.log('Unit cost updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry Unit Cost did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };



  const handleUpdateDiscount = async (userService) => {
    const updateData = { userService: userService, accessToken:accessToken, userDiscount: inputValues['userDiscount'] };    
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_user_discount', updateData);
      // const response = await axios.post('http://localhost:8085/update_user_discount', updateData);
      if (response.status === 200) 
      {
        console.log('Discount updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry discount did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };


  const handleUpdateMinUnit = async (userService) => {
    const updateData = { userService: userService, accessToken:accessToken, userMinUnit: inputValues['userMinUnit'] };    
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_user_minUnit', updateData);
      // const response = await axios.post('http://localhost:8085/update_user_minUnit', updateData);
      if (response.status === 200) {

        console.log('Min Units updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry Min Units did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };



  const handleUpdateMinCharge = async (userService) => {
    const updateData = { userService: userService, accessToken:accessToken, userMinCharge: inputValues['userMinCharge'] };    
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_user_minCost', updateData);
      // const response = await axios.post('http://localhost:8085/update_user_minCost', updateData);
      if (response.status === 200) 
      {
        console.log('Min Charge updated successfully');
      }
      else 
      {
        setErrorMessage('Sorry Min Cost did not get updated. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating the service. Please try again.');
    }
  };
  
    


  const handleDeleteService = async (userService) => {
    const confirmation = window.confirm(`Are you sure you want to delete user costing ${userService} ?`);
    
    if (confirmation) {
      const updateData = { userService: userService, accessToken: accessToken }; 
      try {
        const response1 = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/user_service_delete', updateData);
        // const response1 = await axios.post('http://localhost:8085/user_service_delete', updateData);
        if (response1.status == 200) 
        {
          setErrorMessage('');
          window.location.reload();
        } else 
        {
          setErrorMessage('Sorry service did not get deleted. Try again.');
        }
      } catch (error) {
        console.error('Error deleting service:', error);
        setErrorMessage('An error occurred while deleting the service. Please try again.');
      }
    }
  };


    const fetchServiceData = async () => {
      try {
        const response = await axios.post(`https://tech.cle.org.pk:8085/user_service_detail/${accessToken}`);
        // const response = await axios.post(`http://localhost:8085/user_service_detail/${accessToken}`);
        setCurrentDisplayedService(response.data);
      } catch (error) {
        setErrorMessage('Error while fetching the data.');
        console.error('Error fetching services data failed. :', error);
      }
    };



  useEffect(() => {
    fetchServiceData();
  }, []);



  const renderServiceList = () => {
    return currentDisplayedService.map((service) => (
      <tr key={service.serviceName}> 
        <td className="w-sname"> {service.userService}</td>
        <td className="w-unitcost">{service.userUnitCost}</td>
        <td className="w-discount">{service.userDiscount}</td>
        <td className="w-minunit">{service.userMinUnit}</td>
        <td className="w-mincharge">{service.userMinCharge}</td>
        <td className="w-actions">
          <button type="button" className="btn btn-primary changeServiceBtn" onClick={() => handleShowModal(service)}>View/Update</button>
          <button type="button" className="btn btn-danger btn-font" onClick={() => handleDeleteService(service.userService)}>Delete</button>
        </td>
      </tr>
    ));
  };

  


    return (

<div className='admin-user-costing-view-page'>
    <Helmet>
        <title>User Costing Management</title>
    </Helmet>

    <div style={{marginTop:"-1%"}}></div>

    <div className="breadcrumb">
        <ol className="container breadcrumb">
            <li className="breadcrumb-item active admin-home">
                <a href="/" className='admin-home-text'> Home</a>
                 <a> / </a>
                <a href="/admin_users" className='admin-home-text'>User Management</a>
                <a> / </a>
                <a href="#" className='admin-home-text'>{emailId}</a>
            </li>
        </ol>
    </div>

    <div>
      <button type="button" id="addservbtn" className="btn btn-success" onClick={handleShowAddServiceModal}>Add New User Costing</button>
      <NewCostingWithAuth show={showAddServiceModal} onClose={handleCloseAddServiceModal} accessToken={accessToken}/>
    </div>
    { errorMessage && <p className='login-error-clr '>{errorMessage}</p>}


{/* section 2  */}
    <div className='service-management'> 

      <div className="container-admin-services">
        <div className="admin-panel">
            <div className="admin-services-heading">Existing User Costings</div>
            <div className="admin-panel-body">
                <table className="admin-table table-bordered">
                    <thead>
                        <tr>
                            <th>Service Title</th>
                            <th>Unit Cost</th>
                            <th>Discount(%)</th>
                            <th>Min Units</th>
                            <th>Min Charge</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{renderServiceList()}</tbody>
                </table>
            </div>
        </div>
      </div>


      
{/* section 3  */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton> 
          <Modal.Title className='btn-font'>{selectedService ? selectedService.userService : "Service Title"}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {selectedService && (
            <div className='service-form-properties'>

              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['userUnitCost'] ? 'green-text' : ''}`}> Unit Cost: <span className="required-fields">*</span> </label>
                  <input
                    type="text"
                    className={`form-control service-form-box ${inputChanged['userUnitCost'] ? 'green-border' : ''}`}
                    value={inputValues['userUnitCost'] || selectedService.userUnitCost}
                    onChange={(e) => handleInputChange(e, 'userUnitCost')}
                  />
                  {inputChanged['userUnitCost'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateUnitCost(selectedService.userService)}> Update </button> 
                      )}
              </div>

              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['userDiscount'] ? 'green-text' : ''}`}> Discount: </label>
                  <input
                      type="text"
                      className={`form-control service-form-box ${inputChanged['userDiscount'] ? 'green-border' : ''}`}
                      value={inputValues['userDiscount'] || selectedService.userDiscount}
                      onChange={(e) => handleInputChange(e, 'userDiscount')}
                  />
                  {inputChanged['userDiscount'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateDiscount(selectedService.userService)} > Update </button>
                  )}
              </div>


              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['userMinUnit'] ? 'green-text' : ''}`}> Minimum Unit: </label>
                  <input
                      type="text"
                      className={`form-control service-form-box ${inputChanged['userMinUnit'] ? 'green-border' : ''}`}
                      value={inputValues['userMinUnit'] || selectedService.userMinUnit}
                      onChange={(e) => handleInputChange(e, 'userMinUnit')}
                  />
                  {inputChanged['userMinUnit'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateMinUnit(selectedService.userService)}>Update </button>
                  )}
              </div>

              <div className="form-group services-form-group">
                  <label className={`service-form-label ${inputChanged['userMinCharge'] ? 'green-text' : ''}`}> Minimum Charge: </label>
                  <input
                      type="text"
                      className={`form-control service-form-box ${inputChanged['userMinCharge'] ? 'green-border' : ''}`}
                      value={inputValues['userMinCharge'] || selectedService.userMinCharge}
                      onChange={(e) => handleInputChange(e, 'userMinCharge')}/>
                    
                    {inputChanged['userMinCharge'] && (
                      <button type="button" className="btn btn-success update-button" onClick={() => handleUpdateMinCharge(selectedService.userService)}> Update </button>
                    )}
              </div>

            </div>
          )}
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-danger btn-font" onClick={handleCloseModal}> Close </Button>
        </Modal.Footer>

      </Modal>



    </div>

    <div style={{paddingBottom:"280px"}}></div>


</div>
    )

}
// export default ViewCostingModel;

const CostingWithAuth = withAuth(ViewCostingModel);
export default CostingWithAuth;
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/text_summerization.css';
import '../styles/font_family.css';
import withAuth from '../users/withAuth';


function Text_Summerization() {

  const [text, setText] = useState('');
  // const [volume, setVolume] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const accessToken = localStorage.getItem('accesstoken');
  const navigate = useNavigate();
  const [summarizationType, setSummarizationType] = useState('extractive'); // state for summarization type
  const [volume, setVolume] = useState('1'); // state for number of sentences


  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const jobType = "Urdu Text Summerization";
    const body = { jobType, accessToken, text, volume, summarizationType};

    // Check if the text is empty
    if (text.trim() === '') {
      window.alert('Failed! Field cannot be empty.');
      return;
    }

    // Check character limit
    if (text.length > 3000) {
      window.alert('Length of text in request is greater than the limit of 3000 characters');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/summerization', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)});
      // const response = await fetch('http://192.168.1.121:8085/summerization', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)});
    if (response.ok) 
    {
      setErrorMessage('');
      const outputText = await response.text();
      var output = document.getElementById('transcription1');
      output.value = outputText;
    } 
    else 
    {
      setErrorMessage('Sorry! Output is not generated. Check Text Summarization Service.');
    }
    } 
    catch (error) {
      setErrorMessage(error.message);
    }
    finally {
      setLoading(false);
    }
  }

  const handleOptionChange = (event) => {
    setSummarizationType(event.target.value); // update summarization type
    
  }
  

  return (

  <div className="text-first-box p-2" >
        
    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    )}

    <Helmet>
      <title>CLE Urdu Text Summerization</title>
    </Helmet>

    <div className="m-4 sum-container-well" align='center'>

        <h3 className="text-center tts-heading">CLE Urdu Text Summarization</h3>
        { errorMessage && <p className='stemmer-error-color '>{errorMessage}</p>}

        

        <div className="col-md-3 pos-fields-format" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div>
                <label className="left-label-text">Summarization Type</label>
                <select id="text-voice" onChange={handleOptionChange}>
                  <option value="extractive">Extractive Summarization</option>
                  <option value="abstractive">Abstractive Summarization</option>
                </select>
              </div>

              {/* Conditionally render the "Number of Summarized Sentences" dropdown */}
              {summarizationType === 'extractive' && (
                <div>
                  <label className="left-label-text" style={{ whiteSpace: 'nowrap', marginLeft: '23px' }}>
                    Number of Summarized Sentences
                  </label>
                  <select id="text-voice-2" value={volume} onChange={(event) => setVolume(event.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              )}
            </div>



        <div class="col-md-9 flex-container-button-pos">
          <form onSubmit={handleSubmit}>

                  <label className="form-label text-label-input"> Input Text</label>
                  <textarea
                    type="text"
                    className="form-control text-textarea urduFont"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                  />

                <div style={{padding:'1%'}}></div>

               

                <label className="form-label text-label-output"> Output </label>
                <textarea
                  type="text"
                  className="form-control text-textarea urduFont"
                  id = 'transcription1'
                  readOnly
                />

                <div style={{ paddingTop: '3%' }}></div>
                <div className="text-btn" style={{ display: 'flex', gap: '10px' }}>
                  <button type="submit" className="btn btn pos-button">Submit</button>
                  <Link className="btn btn pos-button" to="/">Cancel</Link>
                </div>


          </form>

        </div>
    </div>

    <div style={{paddingBottom:'5%'}}> </div>

  </div>
  );
}

// export default Text_Summerization;

const Text_SummerizationWithAuth = withAuth(Text_Summerization);
export default Text_SummerizationWithAuth;
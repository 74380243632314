import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import withAuth from '../users/withAuth';
import axios from 'axios';

import '../styles/history.css';

function History() {
  const { jobId } = useParams();
  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    console.log(jobId);
    const fetchJobDetails = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/jobdetails/${jobId}');
        // const response = await axios.post(`http://localhost:8085/jobdetails/${jobId}`);
        setJobDetails(response.data);
      } catch (error) {
        console.error('Error fetching job details:', error);
      }
    };
    fetchJobDetails();
  }, [jobId]);

  return (
    <div className='history-page'>
      <Helmet>
        <title>User Job Detail</title>
      </Helmet>

      {jobDetails ? (
        <div className='history-box'>
          <p className='history-content-heading'>Reference ID:</p>
          <p className='history-content-detail'>{jobDetails.id}</p>

          <p className='history-content-heading'>Cost:</p>
          <p className='history-content-detail'>{jobDetails.cost}</p>

          <p className='history-content-heading'>Service Name:</p>
          <p className='history-content-detail'>{jobDetails.serviceId}</p>

          <p className='history-content-heading'>Date:</p>
          <p className='history-content-detail'>{jobDetails.date}</p>

          <p className='history-content-heading'>Status:</p>
          <p className='history-content-detail'>{jobDetails.remarks}</p>

          <p className='history-content-heading'>Time :</p>
          <p className='history-content-detail'>{jobDetails.time}</p>

          <p className='history-content-heading'>Details :</p>
          <p className='history-content-detail'>{jobDetails.text}</p>

          {jobDetails.parameters ? (
            <div>
              <p className='history-content-heading'>Parameters :</p>
              <p className='history-content-detail'>{jobDetails.parameters}</p>
            </div>
          ) : null}
        </div>
      ) : (
        <p>Loading job details...</p>
      )}
    </div>
  );
}

const HistoryPageWithAuth = withAuth(History);
export default HistoryPageWithAuth;

import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_domain.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function domain_identification() {

  return (


    <div id="content-domain"> 

        <Helmet>
            <title>CLE Urdu Domain Identification Service</title>
        </Helmet>

        <div className="row domain-banner">
                <div className="domain-banner-title">URDU DOMAIN IDENTIFICATION SERVICE </div>
        </div>


        <div className="row domain-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="domain-service-text">
                    <p> CLE Urdu domain identification service classifies documents into a set of predefined categories. Currently, the set of categories include National News, International News,Business,Sports,Science,Health and Showbiz.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="domain-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_domainidentification">
                    <div className="domain-api-ref">
                        <img src={tts_api} alt='domain api'/>
                        <div className="domain-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}



import React from 'react'
import { Nav } from 'react-bootstrap';
import '../styles/admin_navbar.css';

export default function Admin_NavBar() {

  const email = localStorage.getItem('email');
  var isLogin = localStorage.getItem('isLogin');
  const first_name = localStorage.getItem('firstName');


  const handleSubmit1 = () => {  
    isLogin = false;
    localStorage.removeItem('email');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('firstName');
    window.location.replace('/landingPage');
};




  return (

<div>

    <nav className="navbar navbar-expand-lg navbar-line navbar-light">

        <a href="/" className="navbar-brand admin_logo">
          <p>CLE</p>
        </a>

        <button style={{fontFamily:"Raleway"}} className="navbar-toggler menu-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span>Menu</span>
        </button>
        
        <div className="collapse navbar-collapse admin_user_name" id="collapsibleNavbar">
          <ul className="navbar-nav ms-auto">

            <li className="nav-item dropdown">
              <button className="btn btn" data-bs-toggle="dropdown"> 
                <i className="bi bi-person-fill"></i>
                <text> {first_name} </text> 
                <span> &#9660; </span> 
              </button>
              <ul className="dropdown-menu admin-drop-menu">
                <Nav.Link onClick={handleSubmit1} >Log out</Nav.Link>
              </ul>
            </li>

          </ul>
        </div>
    </nav> 

</div>

  );


}

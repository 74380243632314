import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/api_asr.css';


function Static_API_ASR() {
  

  return (

<div>
    <Helmet>
      <title>Urdu Speech to Text API Documentation</title>
    </Helmet>

    <div className="row static-api_asr-center-heading"> 
      URDU SPEECH TO TEXT API REFERENCE
    </div>


{/* python code snippets for a file*/}
<div className="first-box-api_asr p-2" >
  <div className='api-tts-code-heading'>Python</div>
    <div className='api_asr_text'>
        This section presents code snippets for using CLE Urdu Speech to Text API with Python.
        <br/><br/>
        1. These are the required imports.
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
          {`     
    import base64
    import requests
    import json
    import time `}
      </pre>
    </div>

    <div className='api_asr_text'>
      2. The recognition method of CLE Speech to Text web service takes two arguments (input speech in base64 format and access token) in JSON format. Audio file is first converted into base64 string. File must be in wav format with 16000 sampling rate, mono channel and a maximum duration of sixty minute. 
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
          {`     
    file_name = "Place your file name here with extension like audio.wav"
    url = "https://api.cle.org.pk/asr" 
    accessToken = "Place your access token here" 
    
    with open(file_name, 'rb') as f:
      file_data = f.read()

    payload = {
        'file': base64.b64encode(file_data).decode('utf-8'),
        'filename': file_name,         
        'accessToken': accessToken
    } `}
      </pre>
    </div>

    <div className='api_asr_text'>
      3. Use Python client for connecting to the web service as shown below.
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
        {`
    headers = {'Content-type': 'application/json'}
    response = requests.post(url, data=json.dumps(payload), headers=headers)`}
      </pre>
    </div>

    <div className='api_asr_text'>
      4. This HTTP request will return the text.
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
        {`
    decoded_response = response.content.decode('utf-8')
    print(decoded_response)    `}
      </pre>
    </div>

    <div className='api_asr_text'>
      5. With this write command you can get the output text in a output.txt file.
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
        {`
    with open("output.txt", "wb") as f:
      f.write(response.content)
    f.close()    `}
      </pre>
    </div>

  </div>






  {/* java code snippets for a file*/}
  <div className="first-box-api_asr p-2" >
  <div className='api-tts-code-heading'>Java</div>
    <div className='api_asr_text'>
        This section presents code snippets for using CLE Urdu Speech to Text API with Java.
        <br/><br/>
        1. These are the required imports.
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
          {`     
    import java.io.*;
    import java.net.HttpURLConnection;
    import java.net.URL;
    import java.nio.charset.StandardCharsets;
    import java.nio.file.Files;
    import java.nio.file.Paths;
    import java.util.Base64;
    import java.util.Map; `}
      </pre>
    </div>

    <div className='api_asr_text'>
    2. The recognition method of CLE Speech to Text web service takes two arguments (input speech in base64 format and access token) in JSON format. Audio file is first converted into base64 string. File must be in wav format with 16000 sampling rate, mono channel and a maximum duration of sixty minute. 
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
        {`
    String file_name = "Place your file name";
    String accessToken = "Place your access token";
    
    byte[] fileData = Files.readAllBytes(Paths.get(file_name));
    String fileDataString = Base64.getEncoder().encodeToString(fileData);

    String payload = String.format("{\"file\": \"%s\", \"filename\": \"%s\", \"accessToken\": \"%s\"}", fileDataString, file_name, accessToken);`}
      </pre>
    </div>

    <div className='api_asr_text'>
      3. Use JAVA HTTP client for connecting to the web service as shown below. 
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
        {`
    HttpURLConnection connection = null;
    URL apiUrl = new URL(url);
    connection = (HttpURLConnection) apiUrl.openConnection();
    connection.setRequestMethod("POST");
    connection.setRequestProperty("Content-Type", "application/json");
    connection.setDoOutput(true);

    try (OutputStream outputStream = connection.getOutputStream()) {
        outputStream.write(payload.getBytes());
        }    `}
      </pre>
    </div>

    <div className='api_asr_text'>
      4.  This method will return a HTTP response message. The JSON message can be extracted from the HTTP response using the following function. The JSON message contains the output text. The output text will be saved in "output.txt".
    </div>

    <div className="m-4 api_asr-container-well">
      <pre>
        {`
    public class ASRClient {

        public static void main(String[] args) throws IOException {
            String file_name = "Place your file name";
            String url = "https://api.cle.org.pk/asr";
            String accessToken = "Place your access token";

            byte[] fileData = Files.readAllBytes(Paths.get(file_name));
            String fileDataString = Base64.getEncoder().encodeToString(fileData);

            String payload = String.format("{\"file\": \"%s\", \"filename\": \"%s\", \"accessToken\": \"%s\"}",
                    fileDataString, file_name, accessToken);

            HttpURLConnection connection = null;
            try {
                URL apiUrl = new URL(url);
                connection = (HttpURLConnection) apiUrl.openConnection();
                connection.setRequestMethod("POST");
                connection.setRequestProperty("Content-Type", "application/json");
                connection.setDoOutput(true);

                try (OutputStream outputStream = connection.getOutputStream()) {
                    outputStream.write(payload.getBytes());
                }

                int responseCode = connection.getResponseCode();

                if (responseCode == HttpURLConnection.HTTP_OK) {
                    try (BufferedReader reader = new BufferedReader(new InputStreamReader(connection.getInputStream()))) {
                        StringBuilder response = new StringBuilder();
                        String line;

                        while ((line = reader.readLine()) != null) {
                            response.append(line).append("\\n");
                            System.out.println(line);
                        }

                        // Write the response to the file
                        Files.write(Paths.get("output.txt"), response.toString().getBytes());
                    }
                } else {
                    System.out.println("Error: " + responseCode);
                }
            } finally {
                if (connection != null) {
                    connection.disconnect();
                }
            }
        }
    }    `}
      </pre>
    </div>

  </div>

</div>

  );
}

export default Static_API_ASR;

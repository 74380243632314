import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_profanity.css';
import '../styles/font_family.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function Profanity() {

  return (


    <div id="content-profanity"> 

        <Helmet>
            <title>CLE Urdu Content Profanity Estimation Service</title>
        </Helmet>

        <div className="row profanity-banner">
            <div className="profanity-banner-title">URDU CONTENT PROFANITY ESTIMATION SERVICE </div>
        </div>


        <div className="row profanity-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="profanity-service-text">
                        <p>CLE Urdu Profanity Estimation Service computes profanity score of the input text based on a lexicon of inappropriate Urdu words depending on cultural and social context. The profanity score is a number corrected to two decimal places which lies between 0 and 1. The more closer profanity score to 1, the more profane is the content.</p>
                    </div>
                </div>
                
                <div className="row">
                    <a href="/register" className="profanity-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_profanity">
                    <div className="profanity-api-ref">
                        <img src={tts_api} alt='profanity api'/>
                        <div className="profanity-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}

import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/privacy.css';

export default function Privacy() {

    


    return (

        <div className="Page-privacy"> 

            <Helmet>
                <title>Privacy</title>
            </Helmet>


            <div className="col-md-8">
                <h1 className="privacy"> Privacy Statement</h1>
            </div>


            <div className='container-privacy'>

                <pe style= {{textAlign: "justify"}}>
                    At Center for Language Engineering (CLE) we recognize the importance of protecting your personal information and are committed to processing it responsibly.
                    This Privacy Statement describes CLE’s general privacy practices that apply to personal information submitted for using CLE Services. 
                </pe>

                <div className='bo' style={{ textAlign: "left" }}>
                    Why and how we collect and use your personal information
                </div>
                <br></br>

                <pe style= {{textAlign: "justify"}}>
                    We may collect your personal information as an individual for various purposes, such as the following:
                </pe>


                <ul>
                    <li style={{textAlign: "left"}}> 
                        <div className='bo'>
                            Access and use of websites or other online services
                        </div>
                    </li>
                    <pe style= {{textAlign: "justify"}}>
                        When using an online CLE service, we will record information necessary to provide you with access, for the operation of the website and for us to comply with security and legal requirements in relation to operating our site, such as passwords, IP address and browser settings. We also collect information about your activities during your visit in order to personalize your website experience, such as recording your preferences and settings, and to collect statistics to help us improve and further develop our websites, products and services.
                    </pe>

                    <li style={{textAlign: "left"}}> 
                        <div className='bo'>
                            Responding to your request for information, order, or support&nbsp;
                        </div>
                    </li>
                    <pe style= {{textAlign: "justify"}}>
                        When you contact us (online or offline) in connection with a request for information, to order a product or service, to provide you with support, or to participate in a forum or other social computing tool, we collect information necessary to fulfill your request, to grant you access to the product or service, to provide you with support and to be able to contact you. For instance, we collect your name and contact information, details about your request and your agreement with us and the fulfillment, delivery and invoicing of your order and we may include client satisfaction survey information. We retain such information for administrative purposes, defending our rights, and in connection with our relationship with you.&nbsp;
                        When you provide your name and contact information to register in connection with such a request, the registration may serve to identify you when you visit our websites. For ordering of most services and products we require you to a CLE Services account. 
                    </pe>

                    <li style={{textAlign: "left"}}>
                        <div className='bo'>
                            Marketing
                        </div>
                    </li>
                    <pe style= {{textAlign: "justify"}}>
                        Most information we collect about you comes from our direct interactions with you. We combine the personal information we collect to develop aggregate analysis and business intelligence for conducting our business and for marketing purposes. 
                        Where we reference that we use your personal information in connection with a request, order, transaction or agreement (or preparing for the same), or to provide you with services that you requested (such as a website), we do this because it is necessary for the performance of an agreement with you.
                    </pe>
                </ul> 


                <h3 style={{textAlign: "left"}}>Sharing of Personal Information</h3>
                <pe style= {{textAlign: "justify"}}>
                    Please be aware that in certain circumstances, personal information may be subject to disclosure to government agencies pursuant to judicial proceeding, court order, or legal process. We may also share your personal information to protect the rights or property of CLE, our business partners, suppliers or clients, and others when we have reasonable grounds to believe that such rights or property have been or could be affected.
                </pe>


                <h3 style={{textAlign: "left"}}>Information Security and Accuracy</h3>
                <pe style= {{textAlign: "justify"}}>
                    We intend to protect your personal information and to maintain its accuracy. CLE implements reasonable physical, administrative and technical safeguards to help us protect your personal information from unauthorized access, use and disclosure.  
                </pe>


                <h3 style={{textAlign: "left"}}>Retention Period</h3>
                <pe style= {{textAlign: "justify"}}>
                    We will not retain personal information longer than necessary to fulfill the purposes for which it is processed, including the security of our processing complying with legal and regulatory obligations (e.g. audit, accounting and statutory retention terms), handling disputes, and for the establishment, exercise or defense of legal claims in the countries where we do business.
                </pe>


                <h3 style={{textAlign: "left"}}>How to contact us</h3>
                <pe style= {{textAlign: "justify"}}>
                    If you have a question related to this Privacy Statement, please contact us at cletech@cle.org.pk. 
                    Personal information processed in connection with a contractual relationship, or with entering into a contractual relationship with a specific CLE subsidiary. In this case the controller of personal information is the CLE contracting company, which is the legal entity with which you or your employer have, or will have, the contract.
                    Personal information collected on the physical site or location of an CLE subsidiary. In this case that subsidiary is the controller of the personal information.
                </pe>


                <h3 style={{textAlign: "left"}}>Right to Lodge a Complaint</h3>
                <pe style= {{textAlign: "justify"}}>
                    In the event you consider our processing of your personal information not to be compliant with the applicable data protection laws, you can lodge a complaint:
                    Directly with CLE by sending an email at cletech@cle.org.pk
                </pe>


                <h3 style={{textAlign: "left"}}>Changes to our Privacy Statements</h3>
                <pe style= {{textAlign: "justify"}}>
                    From time to time we may update this Privacy Statement, as well as any other specific privacy statement. When making changes to this Privacy Statement, we will add a new date at the top of this Privacy Statement.
                </pe>


            </div>


        </div>
    )
}

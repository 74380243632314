import React, { useState , useEffect , useRef } from 'react'
import { useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/tts.css';
import '../styles/font_family.css';
import withAuth from '../users/withAuth';


function MaleTTS() {

  const [text, setText] = useState('');
  const [volume, setVolume] = useState(100);
  const [speed, setSpeed] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');
  const [wavFile, setWavFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const accessToken = localStorage.getItem('accesstoken');

  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);


  const fetchBalance = async () => {
    try {
        const balanceResponse = await fetch(process.env.REACT_APP_TECH_SERVICE+'/check_balance', {
        // const balanceResponse = await fetch('http://localhost:8085/check_balance', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailId: localStorage.getItem('email') })
      });
  
      if (balanceResponse.ok) {
        const balanceData = await balanceResponse.json();

        setBalance(balanceData);
        return balanceData; 
      } 
      else {
        setBalance(null);
        return null;
      }
    } 
    catch (error) {
      setBalance(null);
      return null;
    }
  };
  



  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsPlaying(true);
    setWavFile(null);

    // Check if the text is empty
    if (text.trim() === '') {
      window.alert('Text field was empty.');
      return;
    }

    // Check character limit
    if (text.length > 1000) {
      window.alert('Length of text in request is greater than the limit of 1000 characters');
      return;
    }

    const jobType = "Urdu Male Text to Speech";
    const body = { jobType, accessToken, text, volume, speed };

    try {
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/male_tts', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)});
      // const response = await fetch('http://localhost:8085/male_tts', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body)});
    if (response.ok) 
    {
      setErrorMessage('');
      const wavFileArray = await response.arrayBuffer();
      setWavFile(new Blob([wavFileArray], { type: 'audio/wav' }));

      const balance = await fetchBalance();
      localStorage.setItem('balance', balance);
    } 
    else 
    {
      setErrorMessage("Audio is not generated. Check Male TTS Service.");
    }
    } 
    catch (error) {
      setErrorMessage(error.message);
    }
    finally {
      setLoading(false);
    }
  }



  const handleStop = () => {
    setLoading(false);
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset audio playback position
    }
  };



  useEffect(() => {
    fetchBalance();
    if (wavFile) {
      if (isPlaying) {
        const audio = new Audio(URL.createObjectURL(wavFile));
        audioRef.current = audio;
        audio.play();
      } else {
        audioRef.current = null;
      }
    }
  }, [wavFile, isPlaying]);


  const navigate = useNavigate();

  function handleVoiceChange(event) {
    const selectedVoice = event.target.value;
  
    if (selectedVoice === 'CLE-NAGHMA-01') {
      console.log('Navigating to /oldfemaletts from male tts page');
      navigate('/utts');
    } else if (selectedVoice === 'CLE-NAGHMA-02') {
      console.log('Navigating to /newfemaletts from male tts page');
      navigate('/female_tts');
    }
  }
  


  return (

<div className="first-box-tts p-2">
    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    )}

  <Helmet>
    <title>CLE Urdu TTS</title>
  </Helmet>

  <div className='left-services'>
    <a href='/asr'>
      Urdu Speech-to-Text
    </a>
  
  </div>

  

  {/* <div className='left-services'>
    <a href=''>
      Speech-to-Speech
    </a>
  </div> */}

  <div className="m-4 container-well" align='center'>
    <div className='col-md-12'>
      <h3 className="text-center tts-heading">Urdu Text-to-Speech</h3>
      <div className='error-message-reset'>
        { errorMessage && <p className='stemmer-error-color '>{errorMessage}</p>}
      </div>

      <div class="col-md-3 fields-format">
        <label className='left-label-tts'> &nbsp; &nbsp; Voice:</label>
          <select id='tts-voice' onChange={handleVoiceChange}>
            <option value="CLE-NAGHMA-01">CLE Naghma</option>
            <option value="CLE-NAGHMA-02">CLE Naghma V-2.0</option>
            <option selected="selected" value="CLE-Male-Voice">CLE Sarang </option>
          </select> 

        {/* <label className="left-label-tts">&nbsp; &nbsp;Volume:</label>
          <input
            className='slider'
            type="range"
            min={0}
            max={200}
            step={10}
            value={volume}
            onChange={(event) => setVolume(event.target.value)}
          />

        <label className="left-label-tts">&nbsp; &nbsp; Rate: </label>
          <input
            className='slider'
            type="range"
            min={-5}
            max={5}
            step={1}
            value={speed}
            onChange={(event) => setSpeed(event.target.value)}
          />  */}
      </div>

      <div className='gap'></div>

      <form onSubmit={handleSubmit}>
        <textarea
          type="text"
          rows={10}
          className="form-control tts-textarea urduFont"
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
        <button type="submit" className="btn btn asr-button">Speak</button>
        <div className="btn btn asr-button" onClick={() => setText('')}>Clear</div>
        <div className="btn btn asr-button" onClick={handleStop}>Stop</div>
      </form>

      

      {wavFile && (
        <audio ref={audioRef} controls hidden>
          <source src={URL.createObjectURL(wavFile)} type="audio/wav" />
        </audio>
      )}

    </div>
  </div>
  <div style={{paddingBottom:'5%'}}> </div>
</div>
  );
}

// export default MaleTTS;

const MaleTTSWithAuth = withAuth(MaleTTS);
export default MaleTTSWithAuth;
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import withAuth from '../users/withAuth';
import '../styles/accesstoken.css';

function Accesstoken() {
  const accessToken = localStorage.getItem('accesstoken');

  useEffect(() => {
    const copyButton = document.getElementById('copy-button');
    const accessTokenInput = document.getElementById('access-token-input');

    const handleClick = () => {
      accessTokenInput.select();
      document.execCommand('copy');
      copyButton.textContent = 'Copied!';

      setTimeout(() => {
        copyButton.textContent = 'Copy';
      }, 2000);
    };

    copyButton.addEventListener('click', handleClick);

    return () => {
      copyButton.removeEventListener('click', handleClick);
    };
  }, []);

  return (

    <div className="first-box-access-token p-2" style={{ paddingTop: '5%' }}>
      <Helmet>
        <title>User Access Token</title>
      </Helmet>

      <div className="m-4 access-container-well" align="center">
        <div className="alert alert-info">
          <a className="close" data-dismiss="alert">×</a>
          <text>You can access your access token from this page.</text>
        </div>

        <form>
          <div className="form-group formating">
            <label className="col-lg-3 access-token-page">Access Token:</label>
            <div className="col-md-4" style={{ marginRight: '16%' }}>
              <input
                id="access-token-input"
                className="form-control"
                type="text"
                readOnly
                value={accessToken}
              />
            </div>
          </div>

          <button id="copy-button" className="btn btn-primary access-token-button"> Copy </button>
        </form>
      </div>
    </div>
  );
}

const AccesstokenWithAuth = withAuth(Accesstoken);
export default AccesstokenWithAuth;

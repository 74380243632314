import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


function withAuth(WrappedComponent) {

  return function(props) {

    const [email, setEmail] = useState('');
    const [isLogin, setIsLoggedIn] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
      const email = localStorage.getItem('email');
      const isLogin = localStorage.getItem('isLogin');
      
      if (!email) 
      {
        setIsLoggedIn(isLogin);
        navigate('/landingPage');
      } 
      else 
      {
        setIsLoggedIn(isLogin);
        setEmail(email);
      }
    }, []);


    return (
      <>
        <WrappedComponent {...props} />
      </>

    );


  };
}

export default withAuth;
import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/api_spell.css';
import withAuth from '../users/withAuth';


function API_spellchecker() {
  

  return (

  <div className="first-box-api_spell p-2" >
    <Helmet>
      <title>Urdu Spell Checking API Documentation</title>
    </Helmet>

    {/* python code snippets */}
    <div className='api-tts-code-heading'>Python</div>
    <div className='api_spell_text'>
        This section presents code snippets for using CLE Urdu Spell Checking API with Python.
        <br/> <br/>
        1. Following are the required imports that will be used.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
          {`
    import requests
    import json `}
      </pre>
    </div>

    <div className='api_spell_text'>
        2. The spell checker method of CLE Urdu Spell Checker web service takes two arguments ( input text and access token) in JSON format.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
          {`
    url = "https://api.cle.org.pk/spell_checker"
    accessToken = "Place your access token here"
    text = "Place text here for spell checking"
    payload = { 
    'text': text, 
    'accessToken': accessToken } `}
      </pre>
    </div>

    <div className='api_spell_text'>
      3. Use Python client for connecting to the web service and returing the output as shown below.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
        {`
    headers = {'Content-type': 'application/json'}
    response = requests.post(url, data=json.dumps(payload), headers=headers)
    print(response.content.decode('utf-8')) `}
      </pre>
    </div>

    <div className='api_spell_text'>
      4. The method will return a JSON response message. This json message has 2 things.
      <br/>
        (i) Result : It gives the status of the output of the task.
        <br/>
        (ii) Response : It gives the output of the provided text.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
        {`
    {  "result" : "Status of the performed task/job",
    "response" : "Output"   } `}
      </pre>
    </div>




    {/* java code snippets */}
    <div className='api-tts-code-heading'>Java</div>
    <div className='api_spell_text'>
        This section presents code snippets for using CLE Urdu Spell Checking API with Java.
        <br/> <br/>
        1. Imports that you require for java code.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
      {`
    import java.io.OutputStream;
    import java.io.BufferedReader;
    import java.io.InputStreamReader;
    import java.net.HttpURLConnection;
    import java.net.URL; `}
      </pre>
    </div>

    <div className='api_spell_text'>
    2. The spell checker method of CLE Urdu Spell Checker web service takes two arguments ( input text and access token).
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
      {`
    String accessToken = "<<your access token>>";
    String text = "<<place your text here>>";  `}
      </pre>
    </div>

    <div className='api_spell_text'>
      3. Whole code should look like this.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
        {`
    import java.io.OutputStream;
    import java.io.BufferedReader;
    import java.io.InputStreamReader;
    import java.net.HttpURLConnection;
    import java.net.URL;

    public class SpellCheckerClient {

        public static void main(String[] args) 
        {
            String url = "https://api.cle.org.pk/spell_checker";
            String accessToken = "<<your access token>>";
            String text = "<<place your text here>>";   
            
            try 
            {
                URL obj = new URL(url);
                HttpURLConnection con = (HttpURLConnection) obj.openConnection();
                con.setRequestMethod("POST");
                con.setRequestProperty("Content-Type", "application/json");
                
                String payload = "{\"text\":\"" + text + "\",\"accessToken\":\"" + accessToken + "\"}";
                
                con.setDoOutput(true);
                OutputStream os = con.getOutputStream();
                os.write(payload.getBytes("UTF-8"));
                os.close();
                
                int responseCode = con.getResponseCode();
                
                if (responseCode == HttpURLConnection.HTTP_OK) 
                {
                    BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
                    String inputLine;
                    StringBuilder response = new StringBuilder();
                    
                    while ((inputLine = in.readLine()) != null) {
                        response.append(inputLine);
                    }
                    in.close();
                    
                    System.out.println(response.toString());
                }

            } catch (Exception e) 
            {
                e.printStackTrace();
            }
        }
    }`}
      </pre>
    </div>




    <div className='api_spell_text'>
      4. The method will return a JSON response message. This json message has 2 things.
      <br/>
        (i) Result : It gives the status of the output of the task.
        <br/>
        (ii) Response : It gives the output of the provided text.
    </div>

    <div className="m-4 api_spell-container-well">
      <pre>
        {`
    {  "result" : "Status of the performed task/job",
    "response" : "Output"   } `}
      </pre>
    </div>

  </div>
  );
}

// export default API_spellchecker;

const API_spellcheckerWithAuth = withAuth(API_spellchecker);
export default API_spellcheckerWithAuth;
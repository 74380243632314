import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/api_keyword.css';
import withAuth from '../users/withAuth';


function API_keywordextraction() {
  

  return (

  <div className="first-box-api_keyword p-2" >
    <Helmet>
      <title>Urdu Keyword Extraction Service API Documentation</title>
    </Helmet>


    {/* python code snippets */}
    <div className='api-tts-code-heading'>Python</div>
    <div className='api_keyword_text'>
        This section presents code snippets for using CLE Urdu Keyword Extraction API with Python.
        <br/> <br/>
        1. Following are the required imports that will be used.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre >
          {`      
    import requests
    import json`}
      </pre>
    </div>

    <div className='api_keyword_text'>
      2. The keyword extraction method of CLE Urdu Keyword Extraction web service takes three arguments ( input text, number of keywords and access token) in JSON format.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
        {`      
    url = "https://api.cle.org.pk/keyword_extraction"
    accessToken = "Place your access token here"
    text = "Place text for Keyword extraction"
    number_of_keywords = "Number of keywords"
    payload = { 
    'text': text, 
    'volume' : number_of_keywords,
    'accessToken': accessToken } `}
      </pre>
    </div>

    <div className='api_keyword_text'>
      3. Use Python client for connecting to the web service and returing the output as shown below.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
        {`
    headers = {'Content-type': 'application/json'}
    try:
      response = requests.post(url, data=json.dumps(payload), headers=headers)
      print(response.content.decode('utf-8'))
    except requests.exceptions.ConnectionError as e:
      print("Error: Connection refused. Make sure the API Gateway is running.")`}
      </pre>
    </div>

    <div className='api_keyword_text'>
      4. The method will return a JSON response message. This json message has 2 things.
      <br/>
        (i) Result : It gives the status of the output of the task.
        <br/>
        (ii) Response : It gives the output of the provided text.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
        {`
    { "result" : "Status of the performed task/job",
    "response" : "Output"   }     `}
      </pre>
    </div>




    {/* java code snippets */}
    <div className='api-tts-code-heading'>Java</div>
    <div className='api_keyword_text'>
      This section presents code snippets for using CLE Urdu Keyword Extraction API with Java.
      <br/> <br/>
      1. Imports that you require for java code.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
      {`
    import java.io.OutputStream;
    import java.io.BufferedReader;
    import java.io.InputStreamReader;
    import java.net.HttpURLConnection;
    import java.net.URL;      `}
      </pre>
    </div>

    <div className='api_keyword_text'>
    2. The keyword extraction service of CLE web services takes three mandatory arguments (input text, access token and number of keywords).
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
      {`
    String accessToken = "<<your access token>>";
    String text = "<<place your text here>>";
    int no_of_keywords = "<<number of extracted keyword>>"; `}
      </pre>
    </div>

    <div className='api_keyword_text'>
    3. Whole code should look like this.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
      {`
    import java.io.OutputStream;
    import java.io.BufferedReader;
    import java.io.InputStreamReader;
    import java.net.HttpURLConnection;
    import java.net.URL;

    public class KeywordExtractionClient {

        public static void main(String[] args) {
            String url = "https://api.cle.org.pk/keyword_extraction";
            String accessToken = "<<your access token>>";
            String text = "<<place your text here>>";
            int no_of_keywords = "<<number of extracted keyword>>"; 
            
            try 
            {
                URL obj = new URL(url);
                HttpURLConnection con = (HttpURLConnection) obj.openConnection();
                con.setRequestMethod("POST");
                con.setRequestProperty("Content-Type", "application/json");
                
                String payload = "{\"text\":\"" + text + "\",\"accessToken\":\"" + accessToken + "\", \"volume\":\"" + no_of_keywords + "\"}";
                
                con.setDoOutput(true);
                OutputStream os = con.getOutputStream();
                os.write(payload.getBytes("UTF-8"));
                os.close();
                
                int responseCode = con.getResponseCode();
                
                if (responseCode == HttpURLConnection.HTTP_OK) 
                {
                    BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
                    String inputLine;
                    StringBuilder response = new StringBuilder();
                    
                    while ((inputLine = in.readLine()) != null) {
                        response.append(inputLine);
                    }
                    in.close();
                    
                    System.out.println(response.toString());
                }

            } catch (Exception e) 
            {
                e.printStackTrace();
            }
        }
    }     `}
      </pre>
    </div>

    <div className='api_keyword_text'>
      4. The method will return a JSON response message. This json message has 2 things.
      <br/>
        (i) Result : It gives the status of the output of the task.
        <br/>
        (ii) Response : It gives the output of the provided text.
    </div>

    <div className="m-4 api_keyword-container-well">
      <pre>
        {`
    { "result" : "Status of the performed task/job",
    "response" : "Output"   }     `}
      </pre>
    </div>

  </div>


  );
}

// export default API_keywordextraction;

const API_keywordextractionWithAuth = withAuth(API_keywordextraction);
export default API_keywordextractionWithAuth;
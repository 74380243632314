import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import withAuth from '../users/withAuth';

import '../styles/admin_user.css';

function SendMailModel({ show, user, onClose }) {

  const [errorMessage, setErrorMessage] = useState('');
  const [inputValues, setInputValues] = useState({ balance: '' });


  const handleCloseModal = () => {
    onClose();
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [field]: value,
    }));
  };


  const handleMail = async (emailId) => {
    const updateData = { emailId: emailId, password: inputValues['password'], message: inputValues['message'] };
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/email_to_user', updateData);
      // const response = await axios.post('http://localhost:8085/email_to_user', updateData);
      if (response.status === 200) 
      {
        handleCloseModal();
        window.location.reload();
      } else {
        setErrorMessage('Sorry, mail sending fail. Try again.');
      }
    } catch (error) {
      console.error('Error mail sending fail:', error);
      setErrorMessage('An error occurred while sending mail. Please try again.');
    }
  };


  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className='btn-font' >Sending Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="users-form-properties">

          <div className="form-group users-mail-form-group">
            <label className="users-mail-form-label">User Email:</label>
            <input type="text" className="form-control users-mail-form-box" value={user.emailId} readOnly />
          </div>

          <div className="form-group users-mail-form-group">
            <label className="users-mail-form-label">Subject:</label>
            <input
              type="text"
              className="form-control users-mail-form-box"
              value={inputValues['password']}
              onChange={(e) => handleInputChange(e, 'password')}
            />
          </div>

          <div className="form-group users-mail-form-group">
            <label className="users-mail-form-label">Content:</label>
            <textarea
              type="text"
              rows={5}
              className="form-control users-mail-form-box"
              value={inputValues['message']}
              onChange={(e) => handleInputChange(e, 'message')}
            />
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-danger btn-font" onClick={handleCloseModal}> Cancel </Button>
        <Button variant="primary" className="btn btn-success user-balance-btn" onClick={() => handleMail(user.emailId)}> Send</Button>
      </Modal.Footer>
    </Modal>
  );
}

// export default SendMailModel;

const SendMailWithAuth = withAuth(SendMailModel);
export default SendMailWithAuth;
import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_pos.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function Postaggar() {

  return (


    <div id="content-pos"> 

        <Helmet>
            <title>CLE Part-Of-Speech (POS) Tagging Service</title>
        </Helmet>

        <div className="row pos-banner">
                <div className="pos-banner-title">URDU PART-OF-SPEECH (POS) TAGGING SERVICE </div>
        </div>


        <div className="row pos-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="pos-service-text">
                        <p>CLE Urdu Part of Speech (POS) tagging service assigns POS tags such as noun, verb, adjective and adverb to each word/token of the input text.</p>
                        <hr className='pos-service-line'></hr>
                        <p>For complete list of CLE POS tagset &nbsp;
                            <a href={process.env.PUBLIC_URL + '/Urdu POS Tagset.pdf'} className='pos-link-pdf'>Click here.</a>
                        </p>
                    </div>
                </div>
                
                <div className="row">
                    <a href="/register" className="pos-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_postagger">
                    <div className="pos-api-ref">
                        <img src={tts_api} alt='pos api'/>
                        <div className="pos-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}

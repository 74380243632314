import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import '../styles/landingPage.css';
import '../fonts/fonts.css';

import text_processing from '../images/text_processing.png';
import speech_processing from '../images/speech_processing.png';
import image_processing from '../images/image_processing.png';
import bulb from '../images/std_img.png';
import get_started from '../icons/landing/access-icon.png';
import phone from '../icons/landing/phone-icon.png';
import address from '../icons/landing/address-icon.png';
import speech from '../icons/landing/speech-bubble-icon.png';

export default function LandingPage() {

    const [formData, setFormData] = useState({ firstName: '', emailId: '', message: '' });
    const [resetSuccess3, setResetSuccess3] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, firstName: value });
      };
    
      const handleEmailChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, emailId: value });
      };
    
      const handleMessageChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, message: value });
      };

  

  const handleSubmit = async (e) => {

    e.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/send_mail', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(formData) });
    //   const response = await fetch('http://localhost:8085/send_mail', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formData)});

      if (response.ok) 
      {
        setResetSuccess3(true);
        setErrorMessage("Your feedback has been submitted. Thank You.");
      } 
      else 
      {
        setResetSuccess3(true);
        setErrorMessage("Email didn't send. Try again.");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



return (

<div> 

    <Helmet>
        <title>CLE Web Services</title>
      </Helmet>

        {/* to display a successful message after submiting feedback */}
      {resetSuccess3 ? (
          <div className='feedback-msg'>
              <p>{errorMessage}</p>
          </div>
          
        ) : (

<div>

    {/* section 1 */}
    <div className="Page">
        <header className="Page-header">
            <div className="banner-title">CLE NLP WEBSERVICES </div>
            <div className="banner-tagline">BUILD APPLICATIONS NOW </div>
        </header>
    </div>


    {/* section 2 */}
    <div id="for-services" className="services-container">
        <div className="row center-heading" > What We Do </div>

                    <div id="service-row" className="row">
                        <div className="col-lg-4 service-column">
                            <a href="/speech_services" className='services_title_design'>
                                <img className="service-img" src={speech_processing} alt='speech processing'/>
                                <div className="category_heading">
                                    <span className="category_heading_title"> Speech Processing</span>
                                </div>
                            </a>
                            <div className="service-box-text"> 
                                <p className="paragraph_text">
                                    Easily Convert Urdu audio to text or convert Urdu text into natural sounding Urdu speech, to enable speech processing capabilities in your applications and services. 
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 service-column">
                            <a href="/text_services" className='services_title_design'> 
                                <img className="service-img" src={text_processing} alt='text processing'/>
                                <div className="category_heading">
                                    <span className="category_heading_title"> Text Processing</span>
                                </div>
                            </a>
                            <div className="service-box-text"> 
                                <p className="paragraph_text">
                                    Analyze unstructured Urdu content and extract lexical, syntactical, and sementical information, to enable advanced language processing capabilities in your applications and services.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 service-column">
                            <a href="/image_services" className='services_title_design'>
                                <img className="service-img" src={image_processing} alt='image_processing'/>
                                <div className="category_heading">
                                    <span className="category_heading_title"> Image Processing</span>
                                </div>
                            </a>
                            <div className="service-box-text"> 
                                <p className="paragraph_text">
                                    Extract text from scanned Urdu documents using Optical Character Recognition (OCR) , to enable document image processing capabilities in your applications and services. 
                                </p>
                            </div>
                        </div>
                    </div>
    </div>


    {/* section 3  */}
    <div id="fellowship-award" className="landing-container-3">	
        <h2 className="fellowship-heading-line"> CLE Fellowship Award </h2>

        <div className="row" style={{marginTop:"50px"}}>
            <div className="pic text-right">
                <img src={bulb} alt='bulb'/>
            </div>
            <div className="col-lg-10 text-left">
                <div style={{marginLeft:"75px"}}>OFFER FOR STUDENTS</div>
                <div className="service-box-text" style={{marginTop:"10px", marginLeft:"71px"}}>
                    <p>Come up with innovative ideas for developing applications, software or tools involving Urdu text/language, speech and image processing. Submit your proposals and win access to APIs of CLE NLP Webservices, equivalent to PKR 30,000. For details, click below</p>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="get-started-column">
                <Link to="https://cle.org.pk/cfp/index.php" className="btn btn-outline btn-cyan">
                    Submit Proposals Here
                </Link>
            </div>
        </div>
         			
    </div>



    {/* section 4 */}
    <div id="get-started-container" className="landing-container-4">
    
        <h2 className="get-started-heading-line white"> Get Started Free</h2>

        <div className="row col-lg-offset-3">
            <div className="col-lg-1">
                <img className="get-started-icon" src={get_started} alt='get started' style={{marginLeft:'60%', marginTop:'18%'}}/>
            </div>
            <div className="col-lg-6">
                <div className="get-started-column-text-heading"> ACCESS TO ALL CLE NLP WEBSERVICES </div>
                <div className="get-started-column-text"> Get everything you need to build and run your apps, websites and services</div>
            </div>  
        </div>

        <div id="get_started_tagline" className="get-started-column">
            Ready To Get Started?
        </div>

        <div className="get-started-column">
            <Link className="btn btn-outline btn-cyan" to="/register" > Register Today! </Link>
        </div>

    </div>



    {/* section 5 */}
    <div id="contact" className=" landing-container">
        <div className="row contact-heading-line"> Contact Us </div>

        <div className="row contact-row" align="center">
            <div className="col-lg-4 contact-column">
                <div className="contact-icon">
                    <img className="img-responsive" src={address} alt='address'/>
                </div>
                <div className="contact-column-text"> UET, Lahore</div>
            </div>

            <div className="col-lg-4 contact-column">
                <div className="contact-icon">
                    <img className="img-responsive" src={speech} alt='speech'/>
                </div>
                <div className="contact-column-text"> info@cle.org.pk</div>
            </div>

            <div className="col-lg-4 contact-column">
                <div className="contact-icon">
                    <img className="img-responsive" src={phone} alt='phone'/>
                </div>
                <div className="contact-column-text"> (+92)423-682-1444 </div>
            </div>
        </div>

        <div id="feedback" className="row contact-row">
            <div id="feedback-text" className="col-lg-12 contact-column" align="center">
                Connect with us and provide your feedback
            </div>
        </div>

        <form onSubmit={handleSubmit}>
            
            <div className="row contact-row form-group">
                <div className="col-lg-6 contact-column">
                    <input type="text" name="name" placeholder="Your Name Here" className="contact-form-field" value={formData.firstName} onChange={handleFirstNameChange} />
                </div>
                <div className="col-lg-6 contact-column">
                    <input type="email" name='email' placeholder="Your Email Here" className="contact-form-field" value={formData.emailId} onChange={handleEmailChange} />
                </div>
            </div>

            <div className="row contact-row form-group">
                <div className="col-lg-12 contact-column">
                    <input type="text" name='message' placeholder="Your Message Here" className="contact-form-field" value={formData.message} onChange={handleMessageChange} />
                </div>
            </div>

            <div className="row contact-row">
                <div className="col-lg-6 col-lg-offset-6 landing-btn">
                    <button type="submit" id="contact-button" className="btn" onClick={handleSubmit}> Send Message</button>
                </div>       
            </div>

        </form>
    </div> 

</div>
)}

</div>

);
}

import React from 'react'
import '../styles/footer.css';

export default function Admin_Footer() {


  return (

  <div id="footer" className="footer-container">

    <div className="footer-row">
        <div className="white">
            © 2023 <a href="https://www.cle.org.pk"><span id="footer-cle">Center for Language Engineering</span></a>, KICS, UET Lahore. All rights reserved.
        </div>
    </div>

  </div>


  )
}

import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import withAuth from '../users/withAuth';

import '../styles/landingPage.css';
import '../fonts/fonts.css';

import phone from '../icons/landing/phone-icon.png';
import address from '../icons/landing/address-icon.png';
import speech from '../icons/landing/speech-bubble-icon.png';

function Contact_login() {

    const [formData, setFormData] = useState({ firstName: '', emailId: '', message: '' });
    const [resetSuccess3, setResetSuccess3] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, firstName: value });
      };
    
      const handleEmailChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, emailId: value });
      };
    
      const handleMessageChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, message: value });
      };

  

  const handleSubmit = async (e) => {

    e.preventDefault();

    try {
      const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/send_mail', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(formData) });
    //   const response = await fetch('http://localhost:8085/send_mail', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(formData)});

      if (response.ok) 
      {
        setResetSuccess3(true);
        setErrorMessage("Your feedback has been submitted. Thank You.");
      } 
      else 
      {
        setResetSuccess3(true);
        setErrorMessage("Email didn't send. Try again.");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



return (

<div> 

    <Helmet>
        <title>CLE Web Services</title>
      </Helmet>

        {/* to display a successful message after submiting feedback */}
      {resetSuccess3 ? (
          <div className='feedback-msg'>
              <p>{errorMessage}</p>
          </div>
          
        ) : (

<div>


    {/* section 5 */}
    <div id="contact" className=" landing-container">
        <div className="row contact-heading-line"> Contact Us </div>

        <div className="row contact-row" align="center">
            <div className="col-lg-4 contact-column">
                <div className="contact-icon">
                    <img className="img-responsive" src={address} alt='address'/>
                </div>
                <div className="contact-column-text"> UET, Lahore</div>
            </div>

            <div className="col-lg-4 contact-column">
                <div className="contact-icon">
                    <img className="img-responsive" src={speech} alt='speech'/>
                </div>
                <div className="contact-column-text"> info@cle.org.pk</div>
            </div>

            <div className="col-lg-4 contact-column">
                <div className="contact-icon">
                    <img className="img-responsive" src={phone} alt='phone'/>
                </div>
                <div className="contact-column-text"> (+92)423-682-1444 </div>
            </div>
        </div>

        <div id="feedback" className="row contact-row">
            <div id="feedback-text" className="col-lg-12 contact-column" align="center">
                Connect with us and provide your feedback
            </div>
        </div>

        <form onSubmit={handleSubmit}>
            
            <div className="row contact-row form-group">
                <div className="col-lg-6 contact-column">
                    <input type="text" name="name" placeholder="Your Name Here" className="contact-form-field" value={formData.firstName} onChange={handleFirstNameChange} />
                </div>
                <div className="col-lg-6 contact-column">
                    <input type="email" name='email' placeholder="Your Email Here" className="contact-form-field" value={formData.emailId} onChange={handleEmailChange} />
                </div>
            </div>

            <div className="row contact-row form-group">
                <div className="col-lg-12 contact-column">
                    <input type="text" name='message' placeholder="Your Message Here" className="contact-form-field" value={formData.message} onChange={handleMessageChange} />
                </div>
            </div>

            <div className="row contact-row">
                <div className="col-lg-6 col-lg-offset-6 landing-btn">
                    <button type="submit" id="contact-button" className="btn" onClick={handleSubmit}> Send Message</button>
                </div>       
            </div>

        </form>
    </div> 

</div>
)}

</div>

);
}


const ContactLoginWithAuth = withAuth(Contact_login);
export default ContactLoginWithAuth;
import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_key.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function Keywordextraction() {

  return (


    <div id="content-key"> 

        <Helmet>
            <title>CLE Urdu Keyword Extraction Service</title>
        </Helmet>

        <div className="row key-banner">
                <div className="key-banner-title">URDU KEYWORD EXTRACTION SERVICE </div>
        </div>


        <div className="row key-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="key-service-text">
                    <p> CLE Urdu Keyword Extraction service helps to extract keywords from the Input Urdu text as per the user request.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="key-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_keywordextraction">
                    <div className="key-api-ref">
                        <img src={tts_api} alt='key api'/>
                        <div className="key-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}




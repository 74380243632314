import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/admin_home.css';
import withAuth from '../users/withAuth';

import service_img from '../images/admin/service.png';
import user_img from '../images/admin/um.png';

function Admin_home() {


    return (

<div className='admin-page'>
    <Helmet>
        <title>Home Page</title>
    </Helmet>

    <div style={{marginTop:"-1%"}}></div>

    <div className="breadcrumb">
        <ol className="container breadcrumb">
            <li className="breadcrumb-item active admin-home">
                <a href="/" className='admin-home-text'>Home</a>
            </li>
        </ol>
    </div>


    <div id="main">
        <div className="w3-container w3-center" >
            <div className="flex-container-admin">
                <button className="w3-white w3-card-4 w3-margin service-card w3-round-large" onClick={() => (window.location.href = '/admin_users')}>
                    <div className="w3-container w3-padding-16 w3-center">
                        <p>Users Management</p>
                        <img className="admin-img1" src={user_img} alt='user'/>
                    </div>
                </button>

                <button className="w3-white w3-card-4 w3-margin service-card w3-round-large" onClick={() => (window.location.href = '/admin_service')}>
                    <div className="w3-container w3-padding-16 w3-center">
                        <p>Service Management</p>
                        <img className="admin-img2" src={service_img} alt='services'/>
                    </div>
                </button>
            </div>
        </div>
    </div>


</div>
    )

}

// export default Admin_home;

const AdminHomeWithAuth = withAuth(Admin_home);
export default AdminHomeWithAuth;
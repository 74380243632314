import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_asr.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function ASR() {

  return (


    <div id="content-asr"> 

        <Helmet>
            <title>CLE Speech-to-Text (STT) Service</title>
        </Helmet>

        <div className="row asr-banner">
                <div className="asr-banner-title">URDU SPEECH-TO-TEXT (STT) SERVICE </div>
        </div>


        <div className="row asr-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="asr-service-text">
                    <p> Urdu STT service converts Urdu speech into text. It enables developers to produce transcripts of Urdu speech using powerful neural network model through an easy-to-use API. Urdu STT API can be used to recognise speech coming from microphone or a recorded file.  This service can be used to write emails and documents with high accuracy.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="asr-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_stt">
                    <div className="asr-api-ref">
                        <img src={tts_api} alt='asr api'/>
                        <div className="asr-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}

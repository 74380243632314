import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import withAuth from '../users/withAuth';

import '../styles/admin_service.css';

function AddServiceModal(props) {

    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newServiceInput, setNewServiceInput] = useState({});

    const handleCloseModal = () => {
        setNewServiceInput({});
        props.onClose();
        setShowModal(false);
        window.location.reload();
    };


    const handleAddService = async () => {
        try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/new_service', newServiceInput);
        // const response = await axios.post('http://localhost:8085/new_service', newServiceInput);
        if (response.status === 200) 
        {
            setShowModal(false);
            window.location.reload();
        } else {
            setErrorMessage('Failed to add the new service. Please try again.');
        }
        } catch (error) {
        setErrorMessage('An error occurred while adding the service. Please try again.');
        }
    };

  return (
    <Modal show={props.show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className='btn-font'>New Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <div className="service-form-properties">
      <div className="form-group services-form-group">
        <label className="service-form-label">Service Name <span className="required-fields">*</span> </label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.serviceName || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, serviceName: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Service Title <span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.serviceTitle || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, serviceTitle: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Unit Cost <span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.unit_cost || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, unit_cost: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Discount(%)<span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.discount || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, discount: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Min Units<span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.min_unit || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, min_unit: e.target.value })}
        />
      </div>

      <div className="form-group services-form-group">
        <label className="service-form-label">Min Charge<span className="required-fields">*</span></label>
        <input
          type="text"
          className="form-control service-form-box"
          required
          value={newServiceInput.min_charge || ''}
          onChange={(e) => setNewServiceInput({ ...newServiceInput, min_charge: e.target.value })}
        />
      </div>
   </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-danger btn-font" onClick={handleCloseModal}> Cancel </Button>
        <Button variant="primary" className="btn btn-success btn-font" onClick={handleAddService}> Add New Service </Button>
      </Modal.Footer>
    </Modal>
  );
}

// export default AddServiceModal;

const AddServiceWithAuth = withAuth(AddServiceModal);
export default AddServiceWithAuth;

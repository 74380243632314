import React from 'react'
import '../styles/footer.css';
import pic from '../icons/footer/facebook.png'
import { useEffect } from 'react';
import { useLocation,} from 'react-router-dom';

export default function Footer_Login() {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
          const element = document.getElementById(location.hash.slice(1));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest', offset: 0 });
      
            setTimeout(() => {
              const viewportHeight = window.innerHeight;
              const elementTop = element.getBoundingClientRect().top;
              const offset = (viewportHeight - element.clientHeight) / 2;
              window.scrollBy(0, elementTop - offset);
            }, 100); 
          }
        }
      }, [location]);


  return (

  <div id="footer" className="footer-container">

    <div className="footer-row" >
        <div className="footer-column white">
                <span>Follow us on </span>  
                <a href="https://www.facebook.com/Center-for-Language-Engineering-134916559997094/">
                    <img className="social-icon" src={pic} alt = "fb-icon"></img>
                </a> 
        </div>
    </div>


    <div className="footer-row">
        <div id="footer-links" className="flex-container">
            <div className="flex-item">
                <a href="/contact_login" className="footer-link white">Contact</a>
            </div>
            <div className="flex-item">
                <a href="/privacy_login" className="footer-link white">Privacy</a>
            </div>
            <div className="flex-item">
                <a href="/terms_login" className="footer-link white">Terms of Use</a>
            </div>
            <div className="flex-item1">
                <a href="/terms_login" className="footer-link white">Accessibility</a>
            </div>
            <div className="flex-item1">
                <a href="/contact_login" className="footer-link white">Feedback</a>
            </div>
        </div>
    </div>



    <div className="footer-row">
        <div className="white">
            © 2023 <a href="https://www.cle.org.pk"><span id="footer-cle">Center for Language Engineering</span></a>, KICS, UET Lahore. All rights reserved.
        </div>
    </div>

  </div>


  )
}

import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/speech_services.css';


export default function Speech_services() {

  return (

<div> 

        <Helmet>
            <title>CLE Speech Services</title>
        </Helmet>

        <div className="Page">
            <header className="Page-header">
                <div className="banner-title">CLE NLP WEBSERVICES </div>
                <div className="banner-tagline">BUILD APPLICATIONS NOW </div>
            </header>
        </div>


        <div className="row static-speech_services-center-heading"> 
            Speech Processing Services
        </div>

    <div className='speech-services-content'>

        <div className="row speech_services_title_box">
            <h2>
                <a href="/tts" className='speech_services_title'> 
                    Urdu Text-to-Speech (TTS) Service
                </a>
            </h2>
            <div className="speech_services_text">
                <p> Urdu TTS service enables the computer to read out Urdu content available in digital forms e.g. emails, websites and documents. This technology can be leveraged to provide information to people who are blind or illiterate population who cannot read or write, through more conventional audio means like telephone, public address systems etc. without human interaction.</p>
                <p>&nbsp;</p>
            </div>
        </div>


        <div className="row speech_services_title_box">
            <h2>
                <a href="/stt" className='speech_services_title'>
                    Speech-to-Speech (STS) Service
                </a>
            </h2>
            <div className="speech_services_text">
                <p>CLE STS service translates Urdu speech into English speech and vice versa. STS REST API enables developers to integrate end-to-end, real time speech translation to their applications or services.</p>
                <p>&nbsp;</p>
            </div>
        </div>


        <div className="row speech_services_title_box">
            <h2>
                <a href="/stt" className='speech_services_title'> 
                    Urdu Speech-to-Text (STT) Service
                </a>
            </h2>
            <div className="speech_services_text">
                <p> Urdu STT service converts Urdu speech into text. It enables developers to produce transcripts of Urdu speech using powerful neural network model through an easy-to-use API. Urdu STT API can be used to recognise speech coming from microphone or a recorded file.  This service can be used to write emails and documents with high accuracy.</p>
                <p>&nbsp;</p>
            </div>
        </div>

    </div>

    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  
</div>



  )
}



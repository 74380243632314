import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/romanization.css';
import '../styles/font_family.css';
import withAuth from '../users/withAuth';


function Romanization() {

  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const accessToken = localStorage.getItem('accesstoken');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    const jobType = "Urdu Romanization";
    const body = { jobType, accessToken, text };
  

    // Check if the text is empty
    if (text.trim() === '') {
      window.alert('Failed! Field cannot be empty.');
      return;
    }

    // Check character limit
    if (text.length > 100) {
      window.alert('Length of text in request is greater than the limit of 100 characters');
      return;
    }


    try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_TECH_SERVICE+'/urdu_roman', {
        // const response = await fetch('http://localhost:8085/urdu_roman', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
  
      if (response.ok) {
        setErrorMessage('');
        const outputText = await response.text();
        var output = document.getElementById('translationR');  
        output.innerHTML = outputText;        //new one

      } else {
        setErrorMessage('Sorry! Output is not generated. Check Urdu Romanization Service.');
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
    finally {
      setLoading(false);
    }
  };
  

  function handleOptionChange(event) {
    const selectedOption = event.target.value;

    if (selectedOption === '/text/textSum') {
      navigate('/Text_Summerization');
    }

    if (selectedOption === "/text/pos") {
      navigate('/pos_tagger');
    }

    if (selectedOption === "/text/keywordextraction") {
      navigate('/Keyword_Extraction');
    }

    if (selectedOption === "/text/udi") {
      navigate('/Domain_Identification');
    }

    if (selectedOption === "/text/usc") {
        navigate('/Spell_Checker');
    }

    if (selectedOption === "/text/stemmer") {
      navigate('/Urdu_stemmer');
    }
  
    if (selectedOption === "/text/roman") {
    navigate('/Urdu_romanization');
    }

    if (selectedOption === "/text/langid") {
      navigate('/Language_identification');
    }

    if (selectedOption === "/text/contentfilter") {
      navigate('/Profanity_estimation');
    }

  }

  return (



  <div className="first-box-roman p-2" >

    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner" />
      </div>
    )}

    <Helmet>
      <title>Roman Urdu to Urdu Script</title>
    </Helmet>

      <div className="m-4 roman-container-well" align='center'>
        <h3 className="text-center roman-heading">CLE Urdu Romanization</h3>
        { errorMessage && <p className='stemmer-error-color '>{errorMessage}</p>}

        <div className="col-md-3 roman-fields-format">
          <label className='left-label-roman'>URDU Text Processing </label>
          <select id='roman-voice' onChange={handleOptionChange}>
              <option value="/text/roman" selected="selected">Roman to Urdu Script</option>
              <option value="/text/pos">Urdu POS Tagging</option>
              <option value="/text/contentfilter">Urdu Profanity Estimation</option>
              <option value="/text/langid">Urdu Language Identification</option>
              <option value="/text/textSum">Urdu Text Summarization</option>
              <option value="/text/udi">Urdu Domain Identification</option>
              <option value="/text/usc" >Urdu Spell Checking</option>
              <option value="/text/keywordextraction">Urdu Keyword Extraction</option>
              <option value="/text/stemmer">Urdu Stemmer</option>
              {/* <option value="/text/segmentation">Urdu Segmentation</option> */}
          </select> 
        </div>

        <div className="col-md-9 flex-container-button-roman">
          <form onSubmit={handleSubmit}>
            <label className="form-label roman-input-label">Input (Max. 100 words are allowed)</label>
            <textarea type="text" className="form-control roman-textarea urduFont" value={text} onChange={(event) => setText(event.target.value)} />

            <div style={{ padding: '1%' }}></div>

            <label className="form-label roman-label">Output</label>
            <textarea type="text" className="form-control roman-textarea urduFont" id="translationR" readOnly />

            <div style={{ paddingTop: '3%' }}> </div>

            <div className='middle-btn-roman'>
              <button type="submit" className="btn btn roman-button">Submit</button>
              <Link className="btn btn roman-button" to="/">Cancel</Link>
            </div>

          </form>
        </div>
      </div>

          <div style={{paddingBottom:'5%'}}> </div>
  </div>


  );
}

// export default Romanization;

const RomanizationWithAuth = withAuth(Romanization);
export default RomanizationWithAuth;
import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/api_tts.css';


function Static_API_TTS() {
  

  return (

<div>

    <Helmet>
      <title>TTS API Documentation</title>
    </Helmet>

    <div className="row static-tts-center-heading"> 
      CLE PUNJABI TTS API REFERENCE 
    </div>


  <div className="first-box-api_tts p-2" >
  <div className='api-tts-code-heading'>Python</div>

    <div className='api_tts_text'>
        This section presents code snippets for using CLE TTS API with Python.
        <br/><br/>
        1. These are the imports that you require.
    </div>

    <div className="m-4 api_tts-container-well">
      <pre>
          {`
    import base64
    import random
    import json
    import requests  `}
      </pre>
    </div>

    <div className='api_tts_text'>
    2. The synthesis method of CLE web services takes three mandatory arguments (url, input text and access token) in JSON format.
    </div>

    <div className="m-4 api_tts-container-well">
    <pre>
          {`
    url = "Place your url here"
    accessToken = "Place your access token here"  
    ttstext = "Place your text here"
    data = json.dumps({'accessToken': accessToken, 'text': ttstext})   
    
    #For male voice (CLE Ektara) use this url "https://api.cle.org.pk/punjabi_male_tts"
    #In current version of male voice, rate and volume settings are not supported. `}
      </pre>
    </div>

    <div className='api_tts_text'>
    3. The method will return a HTTP response message. The JSON message contains result and response (encoded wave file data).
    </div>

    <div className="m-4 api_tts-container-well">
    <pre>
        {`
    headers = {'content-type': 'application/json'}
    ttsResponse = requests.post(url, data=data, headers=headers, verify=False)
    response = ttsResponse.json()['response']
    result = ttsResponse.json()['result']    `}
      </pre>
    </div>
    <div className='api_tts_text'>
      4. The encoded wave file can be extracted from the JSON response using the following lines.
    </div>
    
    <div className="m-4 api_tts-container-well">
      <pre>
        {`
    if result == "Audio Generated successfully.":
      wav_file = open("output_audio.wav", "wb")
      decode_string = base64.b64decode(ttsResponse.json()['response'])
      wav_file.write(decode_string)
      print(result)

    else:
      print(response)     `}
      </pre>
    </div>

    <div className='api_tts_text'>
      5. The method will return a response message.
        <br/>
    </div>

    <div className="m-4 api_tts-container-well">
      <pre>
        {` 
    Audio Generated successfully.    `}
      </pre>
    </div>
  </div>








  <div className="first-box-api_tts p-2" >
    <div className='api-tts-code-heading'>Java</div>

    <div className='api_tts_text'>
        This section presents code snippets for using CLE TTS API with Java.
        <br/><br/>
      1. Imports that you require for java code.
    </div>

    <div className="m-4 api_tts-container-well">
      <pre>
          {`
    import java.io.*;
    import java.net.HttpURLConnection;
    import java.net.URL;
    import java.nio.file.Files;
    import java.util.Base64; `}
      </pre>
    </div>

    <div className='api_tts_text'>
      2. The synthesis method of CLE web services takes two mandatory arguments (input text and access token).
    </div>

    <div className="m-4 api_tts-container-well">
      <pre>
        {`
    String url = <<Place your url here>>;
    String accessToken = <<your access token>>;
    String text =  <<your text>>;
    String payload = "{\"text\":\"" + text + "\", \"accessToken\":\"" + accessToken + "\", \"volume\":\"" + 100 + "\", \"speed\":\"" + 1 + "\"}";

    //For male voice (CLE Sarang) use this url "https://api.cle.org.pk/male_tts"
    //For female voice (CLE Naghma) use this url "https://api.cle.org.pk/tts"
    //The speed paarameter can take values from -5 to +5
    //The volume parameter can take values from 0 to 200
    //In current version of male voice, rate and volume settings are supported.    `}
      </pre>
    </div>

    <div className='api_tts_text'>
      3. Whole Code should look like this.
    </div>

    <div className="m-4 api_tts-container-well">
      <pre>
        {`
    import java.io.*;
    import java.net.HttpURLConnection;
    import java.net.URL;
    import java.nio.file.Files;
    import java.util.Base64;

    public class TTSClient {

        public static void main(String[] args) {
            String url = <<Place your url here>>;
            String accessToken = <<Place your access token here>>;
            String text = <<Place your text here>>;

            try {
                URL obj = new URL(url);
                HttpURLConnection con = (HttpURLConnection) obj.openConnection();
                con.setRequestMethod("POST");
                con.setRequestProperty("Content-Type", "application/json");

                String payload = "{\"text\":\"" + text + "\", \"accessToken\":\"" + accessToken + "\", \"volume\":\"" + 100 + "\", \"speed\":\"" + 1 + "\"}";

                con.setDoOutput(true);
                OutputStream os = con.getOutputStream();
                os.write(payload.getBytes("UTF-8"));
                os.close();

                int responseCode = con.getResponseCode();

                if (responseCode == HttpURLConnection.HTTP_OK) {
                    BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
                    String inputLine;
                    StringBuilder response = new StringBuilder();

                    while ((inputLine = in.readLine()) != null) {
                        response.append(inputLine);
                    }
                    in.close();
                    
                    // Extract the Base64-encoded audio data from the server response
                    int startIndex = response.indexOf("\"response\":\"") + "\"response\":\"".length();
                    int endIndex = response.indexOf("\"", startIndex);
                    String audioBase64 = response.substring(startIndex, endIndex);

                    // Save the audio to a file
                    byte[] audioData = Base64.getDecoder().decode(audioBase64);
                    Files.write(new File("java_audio.wav").toPath(), audioData);
                    System.out.println("Wav file is saved successfully.");
                } else {
                    System.out.println("Wav file is not saved. Try again.");
                }
            } catch (Exception e) {
                e.printStackTrace();
                System.out.println("TTS Failed.");
            }
        }
    }         `}
      </pre>
    </div>

    <div className='api_domain_text'>
      4. The method will return a response message.
    </div>

    <div className="m-4 api_domain-container-well">
      <pre>
        {`
    Audio Generated successfully.      `}
      </pre>
    </div>
  </div>



  </div>

  );
}

export default Static_API_TTS;
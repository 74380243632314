import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import withAuth from '../users/withAuth';
import { useNavigate } from 'react-router-dom';

import '../styles/admin_user.css';
import AddBalanceModal from './AddBalanceModel';
import SendMailModel from './SendMailModel';

function Admin_users() {

    const [currentDisplayedUser, setCurrentDisplayedUser] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedUser, setSelectedUser] = useState(null);
    const [showAddBalanceModal, setShowAddBalanceModal] = useState(false);
    const [showSendMailModal, setShowSendMailModal] = useState(false);

    const navigate = useNavigate();
  
    const openAddBalanceModal = (user) => {
      setSelectedUser(user);
      setShowAddBalanceModal(true);
    };
    
    const openSendMailModal = (user) => {
      setSelectedUser(user);
      setShowSendMailModal(true);
    };



    const fetchUserData = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/users_detail', {});
        // const response = await axios.post('http://localhost:8085/users_detail', {});
        setCurrentDisplayedUser(response.data);
      } catch (error) {
        setErrorMessage('Error while fetching the data.');
        console.error('Error fetching users data failed. :', error);
      }
    };
    


    const handleSuspendUser = async (accesstoken) => {
    const confirmation = window.confirm('Are you sure you want to suspend this user ?');
    if (confirmation) {
      try {
        const response1 = await axios.post(`https://tech.cle.org.pk:8085/user_suspend/${accesstoken}`);
        // const response1 = await axios.post(`http://localhost:8085/user_suspend/${accesstoken}`);
        if (response1.status == 200) 
        {
          window.location.reload();
        } else 
        {
          setErrorMessage('Sorry user did not get suspended. Try again.');
        }
      } catch (error) {
        console.error('Error suspending user:', error);
        setErrorMessage('An error occurred while suspending the user. Please try again.');
      }
    }
  };



      const handleUnsuspendUser = async (accesstoken) => {
      const confirmation = window.confirm('Are you sure you want to activate this user ?');
      if (confirmation) {
        try {
          const response1 = await axios.post(`https://tech.cle.org.pk:8085/user_activate/${accesstoken}`);
          // const response1 = await axios.post(`http://localhost:8085/user_activate/${accesstoken}`);
          if (response1.status == 200) 
          {
            window.location.reload();
          } else 
          {
            setErrorMessage('Sorry user did not get activated. Try again.');
          }
        } catch (error) {
          console.error('Error activating user:', error);
          setErrorMessage('An error occurred while activating the user. Please try again.');
        }
      }
    };


    const handleDeleteUser = async (accesstoken) => {
      const confirmation = window.confirm('Are you sure you want to delete this user ?');
      if (confirmation) {
        try {
          const response1 = await axios.post(`https://tech.cle.org.pk:8085/user_delete/${accesstoken}`);
          // const response1 = await axios.post(`http://localhost:8085/user_delete/${accesstoken}`);
          if (response1.status == 200) 
          {
            window.location.reload();
          } else 
          {
            setErrorMessage('Sorry user did not get deleted. Try again.');
          }
        } catch (error) {
          console.error('Error deleting user:', error);
          window.location.reload();
        }
      }
    };




  useEffect(() => {
    fetchUserData();
  }, []);


  const openSummaryModal = (accessToken) => {
    navigate(`/users_summary/${accessToken}`);
  };



  const ViewCosting = (emailId, accessToken) => {
    navigate(`/users_costing/${emailId}`, {state: { accessToken } });
  };


  const renderUserList = () => {
    return currentDisplayedUser.map((user) => (
      <React.Fragment key={user.accessToken}>
        <tr>
          <td className="w-firstName"> {user.firstName} {user.lastName}</td>
          <td className="w-emailId"> {user.emailId}</td>
          <td className="w-balance">{user.balance}</td>
          <td className="w-actions changeUserBtn">
            <button type="button" className="btn btn-success" onClick={() => openAddBalanceModal(user)}>Add balance</button>
            <button type="button" className="btn btn-primary" onClick={() => openSendMailModal(user)}>Send Mail</button>
            <button type="button" className="btn btn-secondary" onClick={() => handleSuspendUser(user.accessToken)}>Suspend User</button>
            <button type="button" className="btn btn-dark" onClick={() => handleUnsuspendUser(user.accessToken)}>Activate User</button>
            <button type="button" className="btn btn-info" onClick={() => openSummaryModal(user.accessToken)}>View Summary</button>
            <button type="button" className="btn btn-warning" onClick={() => ViewCosting(user.emailId, user.accessToken )}>View Costings</button>
            <button type="button" className="btn btn-danger" onClick={() => handleDeleteUser(user.accessToken)}>Delete User</button>
          </td>
        </tr>
        {showAddBalanceModal && (<AddBalanceModal show={showAddBalanceModal} onClose={() => setShowAddBalanceModal(false)} user={selectedUser} /> )}
        {showSendMailModal && (<SendMailModel show={showSendMailModal} onClose={() => setShowSendMailModal(false)} user={selectedUser} />)}
      </React.Fragment>
    ));
  };
  


  const SearchUserData = async (name) => {
    try {
        const response = await axios.post(`https://tech.cle.org.pk:8085/search_user/${name}`);
        // const response = await axios.post(`http://localhost:8085/search_user/${name}`);
        setCurrentDisplayedUser(response.data);
    } catch (error) {
        setErrorMessage('Error while fetching the data.');
        console.error('Error fetching users data failed. :', error);
    }
};
  


return (

<div className='admin-users-page'>
    <Helmet>
        <title>User Management</title>
    </Helmet>
    <div style={{marginTop:"-1%"}}></div>

    <div className="breadcrumb">
        <ol className="container breadcrumb">
            <li className="breadcrumb-item active admin-home">
                <a href="/" className='admin-home-text'> Home</a>
                 <a> / </a>
                <a href="/admin_users" className='admin-home-text'>User Management</a>
            </li>
        </ol>
    </div>

    { errorMessage && <p className='login-error-clr '>{errorMessage}</p>}
    <input type="text" id="searchBox" placeholder="Search users" onChange={(e) => SearchUserData(e.target.value)}></input>

{/* section 2  */}
    <div className='users-management'> 
      <div className="container-admin-users">
        <div className="admin-users-panel-body">
            <table className="admin-users-table table-bordered">
                <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Current Balance</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{renderUserList()}</tbody>
            </table>
        </div>
      </div>
    </div>

</div>

  )

}

// export default Admin_users;

const AdminUserWithAuth = withAuth(Admin_users);
export default AdminUserWithAuth;
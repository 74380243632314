import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_stem.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function urdustemmer() {

  return (


    <div id="content-stem"> 

        <Helmet>
            <title>CLE Urdu Stemmer Service</title>
        </Helmet>

        <div className="row stem-banner">
                <div className="stem-banner-title">URDU STEMMER SERVICE </div>
        </div>


        <div className="row stem-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="stem-service-text">
                    <p> 
                      CLE Urdu stemmer web service provides the stemmed version of an input Urdu text content. Following the set of rules developed in CLE and discussed in the paper titled&nbsp;
                      <a href={process.env.PUBLIC_URL + '/Assas-Band-Camera-Ready.pdf'} className='stem-link-pdf'>"Assas-band, an Affix-Exception-List Based Urdu Stemmer"</a>
                      , we have made Urdu stemmer as a web service for the students and researchers working in the area Urdu NLP. 
                    </p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="stem-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_stemmer">
                    <div className="stem-api-ref">
                        <img src={tts_api} alt='stem api'/>
                        <div className="stem-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}





import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_roman.css';
import '../styles/font_family.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function Roman() {

  return (


    <div id="content-roman"> 

        <Helmet>
            <title>CLE Roman to Urdu Script Service</title>
        </Helmet>

        <div className="row roman-banner">
                <div className="roman-banner-title">ROMAN TO URDU SCRIPT SERVICE </div>
        </div>


        <div className="row roman-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="roman-service-text">
                    <p> CLE Roman to Urdu script service converts input roman text to Urdu script.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="roman-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_roman">
                    <div className="roman-api-ref">
                        <img src={tts_api} alt='roman api'/>
                        <div className="roman-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}




import React from 'react';
import { Helmet } from 'react-helmet';

import '../styles/Fellowship.css';

export default function Fellowship() {

  

  return (

    <div >
        <Helmet>
        <title>Fellowship Award Proposal Submission</title>
        </Helmet>

        {/* section 1 */}
        <div className="Page-fellow">
          <header className="Page-header">
              <div className="banner-title-fellow">CLE NLP WEBSERVICES </div>
              <div className="banner-tagline-fellow">BUILD APPLICATIONS NOW </div>
          </header>
        </div>




        {/* section 2 */}
            <div className='container'>
                  <table>  
  
                    <tbody>
                      
                      <tr>
                        <td>
                          <div className="center-heading-fellow"> CLE Fellowship Award </div>
                          <div className='call-proposal'>Call for Proposals</div>
                        </td>
                      </tr>
  

                      <tr className="paragraph_text-fellow">
                        <td>
                          CLE Fellowship Award is to facilitate those students who are looking forward to developing applications, software or tools involving Urdu text/language, speech and image processing. Students with promising ideas will be provided access to APIs of CLE NLP Webservices, equivalent to PKR. 30,000, which can be used for the successful development and execution of proposed projects.
                          <br></br> 
                          <br></br> 
                          Proposals will be evaluated on the innovativeness of ideas presented, which can contribute towards filling the existing market gaps and facilitating Pakistani citizens in general. The sub-themes are as follows, but are not limited to: 
                        </td>
                      </tr>

                      <br></br> 


                      <table className='fellow-table'>
                        <tbody>
                          <tr>
                            <th> Language</th>
                            <th> Speech </th>
                            <th> Image </th>
                          </tr>
                          
                          <tr>
                            <td style={{padding:"6px"}}> Localization</td>
                            <td> Accent &amp; Language Identification</td>
                            <td> Keyword Spotting </td>
                          </tr>

                          <tr>
                            <td style={{padding:"6px"}}> Morphological Analysis</td>
                            <td> Text-to-Speech Synthesis</td>
                            <td> Optical Character Recognition</td>
                          </tr>

                          <tr>
                            <td style={{padding:"6px"}}> Parsing</td>
                            <td> Automatic Speed Recognition</td>
                            <td> Automatic Script Detection</td>
                          </tr>

                          <tr>
                            <td style={{padding:"6px"}}> Machine Translation </td>
                            <td> Speaker Identification</td>
                            <td> Camera Captured OCR</td>
                          </tr>

                          <tr >
                            <td style={{padding:"6px"}}> Sentiment Analysis</td>
                            <td> Emotion Synthesis</td>
                            <td> Document Image Understanding</td>
                          </tr>

                          <tr>
                            <td style={{padding:"6px"}}> Summarization</td>
                            <td> Emotion Recognition</td>
                            <td> Handwriting Recognition</td>
                          </tr>

                          <tr >
                            <td> </td>
                            <td> Dialogue System</td>
                            <td> </td>
                          </tr>

                        </tbody>
                      </table>

                          <h2 className='fellow-heading'> Key Elements of  Proposal</h2>
                          <div className='fellow-details'>
                            The template of the proposal document can be 
                            <a href={process.env.PUBLIC_URL + '/CLE Fellowship Award - Proposal Template.docx'} > downloaded here</a>. You are requested to strictly follow the guidelines, illustrated in the document, for final submission considerations.
                          </div>

                          <h2 className='fellow-heading'> Eligibility</h2>
                          <div className='fellow-details'>
                            Applicants should be the academics and/or BSc./M.S/M.Phil./Ph.D students. They must be from the disciplines of Computer Science, Electrical Engineering, Applied Linguistics or Computer Engineering.
                          </div>

                          <h2 className='fellow-heading'> Application Process</h2>
                          <div className='fellow-details'>
                            Proposals for CLE Fellowship Award will be evaluated by our panel of experts throughout a year. There is no deadline for submitting a proposal. Participating applicants will be given initial feedback by us within 6 weeks after submission of the proposal.
                            <br></br>
                            The winning applicants for CLE Fellowship Award will be provided access to APIs of CLE NLP Webservices, equivalent to PKR. 30,000. These credits can only be used for the period of one year, during which the project in the proposal should be developed and executed. Certificates will be presented to applicants upon successful completion of the tenure.
                            <br></br>
                          </div>
                          
                          <h2 className='fellow-heading'> Submission of Proposals</h2>
                          <div className='fellow-details'>
                            Complete proposals should be 
                            <a href="/fellowship_proposal"> submitted here. </a> 
                            {/* <a href="https://cle.org.pk/cfp/cfp.php"> submitted here. </a>  */}
                          </div>

                        </tbody>
                      </table>
                      
                      <br></br>                       <br></br>
                      <br></br>                       <br></br>
                      <br></br>                       <br></br>

              </div>
    </div>
  );
}

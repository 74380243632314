import React from 'react'
import { Helmet } from 'react-helmet';
import '../styles/static_spell.css';

import tts_api from '../icons/tts/api-ref-icon.png';

export default function spellchecker() {

  return (


    <div id="content-spell"> 

        <Helmet>
            <title>CLE Urdu Spell Checking Service</title>
        </Helmet>

        <div className="row spell-banner">
                <div className="spell-banner-title">URDU SPELL CHECKING SERVICE </div>
        </div>


        <div className="row spell-service-row">
          
            <div className="col-lg-8">
                <div className="row">
                    <div className="spell-service-text">
                    <p> CLE Urdu Spell Check Service provides assistance for verifying spellings of Urdu Language. It accepts Urdu text as input and checks it for spelling errors. If an error is identified, it generates a ranked list of suggested words.</p>
                    </div>
                </div>
                <div className="row">
                    <a href="/register" className="spell-service-btn">  
                        START SERVICE FOR FREE
                    </a>
                </div>
            </div>

            <div className="col-lg-4">
                <a href="/static_api_spell">
                    <div className="spell-api-ref">
                        <img src={tts_api} alt='spell api'/>
                        <div className="spell-api-ref-text">API REFERENCE</div>
                    </div>
                </a>
            </div>
        </div>
  
    </div>



  )
}



import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import withAuth from '../users/withAuth';

import '../styles/admin_user.css';

function AddBalanceModal({ show, user, onClose }) {

  const [errorMessage, setErrorMessage] = useState('');
  const [inputValues, setInputValues] = useState({ balance: '' });


  const handleCloseModal = () => {
    onClose();
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [field]: value,
    }));
  };


  const handleBalance = async (accesstoken) => {
    const updateData = { accessToken: accesstoken, balance: inputValues['balance'], };
    try {
      const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/update_balance', updateData);
      // const response = await axios.post('http://localhost:8085/update_balance', updateData);
      if (response.status === 200) 
      {
        handleCloseModal();
        window.location.reload();
      } else {
        setErrorMessage('Sorry, user balance did not get updated. Try again.');
      }
    } catch (error) {
      console.error('Error updating user balance:', error);
      setErrorMessage('An error occurred while updating the user balance. Please try again.');
    }
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className='btn-font' >Add User Balance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="users-form-properties add-balance-font">
          <div className="form-group users-form-group">
            <label className="users-form-label">User Name:</label>
            <input type="text" className="form-control users-form-box" value={`${user.firstName} ${user.lastName}`} readOnly />
          </div>

          <div className="form-group users-form-group">
            <label className="users-form-label">User Email:</label>
            <input type="text" className="form-control users-form-box" value={user.emailId} readOnly />
          </div>

          <div className="form-group users-form-group">
            <label className="users-form-label">Current Balance:</label>
            <input type="text" className="form-control users-form-box" value={user.balance} readOnly />
          </div>

          <div className="form-group users-form-group">
            <label className="users-form-label">Amount:</label>
            <input
              type="text"
              className="form-control users-form-box"
              value={inputValues['balance']}
              onChange={(e) => handleInputChange(e, 'balance')}
            />
            <Button variant="primary" className="btn btn-success user-balance-btn" onClick={() => handleBalance(user.accessToken)}> Add Balance </Button>
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn btn-danger btn-font" onClick={handleCloseModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

// export default AddBalanceModal;

const AddBalanceWithAuth = withAuth(AddBalanceModal);
export default AddBalanceWithAuth;
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import '../styles/Fellowship_award.css';

export default function Fellowship_award() {

  const [additionalSupport, setAdditionalSupport] = useState(false);

  const handleSupportChange = (e) => {
    setAdditionalSupport(e.target.value === 'Yes');
  };


  const text = "\t\t\t CLE Fellowship Award & Terms Conditions  \n\nUpon acceptance of a proposal by the evaluation panel, applicant(s) will be eligible to avail CLE Fellowship Award on terms and conditions: \n\n1. The free credits of PKR. 30,000 can be used within the period of one year only. After the completion of time period, any remaining credit will be taken back. \n\n2. This facility can be used to develop and execute what is specified in the proposal. \n\n3. CLE reserves the right to cancel or discontinue CLE NLP Webservices without providing any prior notice to the winning applicants of CLE Fellowship Award. \n\n4. If winning applicants of CLE Fellowship Award want to voluntarily discontinue or cancel the facility of using CLE NLP Webservices, then they will be required to inform and submit 15 days prior intimation to CLE. \n\n5. The winning applicants of CLE Fellowship Award must agree to “CLE Terms of Service” mentioned on the website: \nhttps://tech.cle.org.pk/terms \n\n\n\n"
  



  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  
  const options = [
    { value: "Urdu TTS", label: "Urdu Text-to-Speech (TTS) Service" },
    { value: "Urdu STT", label: "Urdu Speech-to-Text (STT) Service" },
    // { value: "Urdu STS", label: "Urdu Speech-to-Speech (STS) Service" },
    { value: "Urdu POS", label: "Urdu Part-of-Speech (POS) Tagging Service" },
    { value: "Urdu Content Profanity", label: "Urdu Content Profanity Estimation Service" },
    { value: "Language Identification Service", label: "Language Identification Service" },
    { value: "Urdu Text Summarization", label: "Urdu Text Summarization Service" },
    { value: "Urdu Domain Identification", label: "Urdu Domain Identification Service" },
    { value: "Urdu Spell Checking Service", label: "Urdu Spell Checking Service" },
    { value: "Roman to Urdu Script", label: "Roman to Urdu Script Service" },
    { value: "Urdu Keyword Extraction", label: "Urdu Keyword Extraction Service" },
    // { value: "Urdu OCR", label: "Urdu Optical Character Recognition (OCR)" },
    // { value: "CCARS", label: "Camera Captures Address Recognition and Structuring" },
  ];

  const showCheckboxesHandler = () => {
    setShowCheckboxes(!showCheckboxes);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(value)) {
        return prevSelectedOptions.filter((option) => option !== value);
      } else {
        return [...prevSelectedOptions, value];
      }
    });
  };





  return (

    <div >
        <Helmet>
        <title>Fellowship Award</title>
        </Helmet>

        {/* section 1 */}
        <div className="Page-fellow">
          <header className="Page-header">
              <div className="banner-title-fellow">CLE NLP WEBSERVICES </div>
              <div className="banner-tagline-fellow">BUILD APPLICATIONS NOW </div>
          </header>
        </div>



        {/* section 2 */}
            <div class="container" style={{fontFamily:"Raleway"}}>

                  <table className='fellow-award-table'>  
  
                    <tbody>
                      
                      <tr>
                        <td>
                          <div className="center-heading-fellow-award"> CLE Fellowship Award </div>
                          <div className='fellow-call-proposal'>Application Form</div>
                        </td>
                      </tr>

                      <br></br> 


                      <tr>
                        <td>
                          <span>
                            <form>
                              <table cellpadding="4">
                                <tbody>
      
                                  <tr>
                                    <td colspan="10" className="fellow-award-heading"><b>Instructions</b></td>
                                  </tr>

                                  <tr>
                                    <td colspan="10" class="paragraph_text-fellow-award"><ol>
                                      <li>Only one applicant requires to fill this form. He/she will be contacted for all future correspondences. If there are more than one applicants per proposal, then mention the names of all other applicants in the proposal document only.  </li>
                                      <br></br>
                                      <li>The supervisor recommendation is necessary for CLE Fellowship Award applications. Kindly provide supervisor's information, and his/her signed recommendation letter on official letterhead in the section below.</li>
                                    </ol></td>
                                  </tr>


                                  <tr>
                                    <td colspan="10" class="fellow-award-heading"><b>Applicant Information</b></td>
                                  </tr>
                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2"><span>Applicant’s Name</span><span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="300" placeholder="Enter Applicant Name" pattern="([a-zA-Z0-9.- ]| |/|\|@|#|$|%|&amp;|,|-)+" required></input>
                                    </td>
                                  </tr>
                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2"><span>Institution</span><span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="300" placeholder="Enter Your Full Institution Name" pattern="([a-zA-Z0-9.- ]| |/|\|@|#|$|%|&amp;|,|-)+" required></input>
                                    </td>
                                  </tr>
                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2"><span>Institution Address</span><span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="300" placeholder="Enter Your Postal Address" pattern="([a-zA-Z0-9.- ]| |/|\|@|#|$|%|&amp;|,|-)+" required>
                                      </input>
                                    </td>
                                  </tr>
                                  <br></br>   <br></br>
                                  

                                  <tr className='fellow-form-label'>
                                    <td colspan="2"><span>Academic Level</span><span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <select className='fellow-form-input' style={{width:'45%', fontSize:'medium'}}  required>
                                        <option selected="true" disabled value="">Please Select</option>
                                        <option value="Bachelor's degree">Bachelor's degree</option>
                                        <option value="Master's degree">Master's degree</option>
                                        <option value="Doctorate or higher">Doctorate or higher</option>
                                      </select></td>
                                  </tr>

                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Semester<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <select className='fellow-form-input' style={{width:'45%', fontSize:'medium'}} required>
                                        <option selected="true" disabled value="">Please Select</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                      </select>
                                    </td>
                                  </tr>


                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Contact Number<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="11" placeholder="Enter Your 11-digit Mobile Number without spaces" required pattern="[0-9]{11}"></input>
                                    </td>
                                  </tr>


                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Email Address<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="100" placeholder="Enter Your Email Address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required></input>
                                    </td>
                                  </tr>


                                  <br></br>   <br></br>


                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Research Motivation<span className='red-star'>*</span> <br></br> (Less than 150 words)</td>
                                    <td colspan="7">
                                      <textarea className='fellow-form-input' cols="38" rows="5" maxlength="150" required></textarea>
                                    </td>
                                  </tr>


                                  <br></br>   <br></br>



                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Services to be used during Project<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <div className="multiselect fellow-form-input">
                                        <div className="selectBox" onClick={showCheckboxesHandler}>
                                          <select style={{ fontWeight: 'normal', fontSize: 'medium' }}>
                                            <option>Please Select</option>
                                          </select>
                                          <div className="overSelect"></div>
                                        </div>
                                        <div id="checkboxes" style={{fontWeight: 'normal', display: showCheckboxes ? 'block' : 'none'}}>
                                          {options.map((option) => (
                                            <label key={option.id} htmlFor={option.id}>
                                              <input
                                                type="checkbox"
                                                id={option.id}
                                                name={option.name}
                                                value={option.value}
                                                onChange={handleCheckboxChange}
                                                checked={selectedOptions.includes(option.value)}
                                              />
                                              {option.label}
                                            </label>
                                          ))}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>


                                  <br></br>   <br></br>
                     

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Upload Research Proposal File<span className='red-star'>*</span> <br></br> (PDF document)</td>
                                    <td colspan="7"><input className='fellow-form-input' id="frm_fld_9" type="file" size="39" required /></td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Upload CV<span className='red-star'>*</span> <br></br> (PDF Document)</td>
                                    <td colspan="7"><input className='fellow-form-input' id="frm_fld_10" type="file" placeholder="Upload Receipt" size="39" required /></td>
                                  </tr>

                                  <br></br>   <br></br>


                                <tr className='fellow-form-label'>
                                  <td colspan="2">Additional Support Required<span className='red-star'>*</span></td>
                                  <td colspan="7">

                                    <label style={{marginLeft:"20%"}} htmlFor="yesCheck">&nbsp;Yes&nbsp;</label>
                                    <input className="fellow-box" type="radio" value="Yes" onChange={handleSupportChange} id="yesCheck" name="supportOption" />

                                    <label htmlFor="noCheck">&nbsp; No</label>
                                    <input className="fellow-box" type="radio" value="No" onChange={handleSupportChange} id="noCheck" name="supportOption" />

                                    {additionalSupport && (
                                      <div id="others">
                                        <textarea className='fellow-form-input' cols="38" rows="5"></textarea>
                                      </div>
                                    )}
                                  </td>
                                </tr>


                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td className="fellow-award-heading" colspan="10"><b>Supervisor Information</b></td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Supervisor's Name<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="300" placeholder="Enter Supervisor Name" pattern="([a-zA-Z0-9.-]| |/|\|@|#|$|%|&amp;|,|-)+" required />
                                    </td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Designation<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="300" placeholder="Enter Supervisor Designation" pattern="([a-zA-Z0-9.- ]| |/|\|@|#|$|%|&amp;|,|-)+" required />
                                    </td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Institution<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="300" placeholder="Enter Your Full Instituation Name" pattern="([a-zA-Z0-9.- ]| |/|\|@|#|$|%|&amp;|,|-)+" required />
                                    </td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Contact Number<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <input className='fellow-form-input' type="text" size="50" maxlength="11" placeholder="Enter Your 11 digit Mobile Number without spaces" required pattern="[0-9]{11}" />
                                    </td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Email Address<span className='red-star'>*</span></td>
                                    <td colspan="7"><input className='fellow-form-input' type="text" size="50" maxlength="100" placeholder="Enter Your Email Address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required /></td>
                                  </tr>

                                  <br></br>   <br></br>

                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Upload Supervisor’s Recommendation Letter<span className='red-star'>*</span></td>
                                    <td colspan="7"><input className='fellow-form-input' id="frm_fld_16" type="file" size="39" required /></td>
                                  </tr>

                                  <br></br>   <br></br>


    
                                  <tr className='fellow-form-label'>
                                    <td colspan="2">Terms &amp; Conditions<span className='red-star'>*</span></td>
                                    <td colspan="7">
                                      <textarea className='fellow-form-input' rows="8" cols="70" readonly="readonly">
                                        {text}
                                      </textarea>

                                      <br></br>
                                      
                                      <input className='fellow-form-input' type="checkbox" value="agreed"/> Agreed
                                      <input className='fellow-form-input' type="checkbox" value="disagreed"/> Disagreed
                                      
                                    
                                    </td>
                                  </tr>


    
                                  <tr className='fellow-form-label'>
                                    <td colspan="10">
                                      <span style={{color:"red"}}>
                                        <span className='red-star'>*</span>Mandatory Fields
                                      </span>
                                    </td>
                                  </tr>


                                  <tr className='fellow-form-label'>
                                    <td colspan="10" style={{ textAlign: 'center' }}>
                                      <input type="submit" className="button fellow-btn" />
                                    </td>
                                  </tr>

                                  <br></br>   <br></br>

                                </tbody>
                              </table>
                            </form> 
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                      
                      <br></br>
                      <br></br>
                      <br></br>
                      <br></br>

            </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import withAuth from '../users/withAuth';
import axios from 'axios';

import '../styles/home.css';


function ViewSummaryModel() {

  const { accessToken } = useParams();
  const [jobList, setJobList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDisplayedJobs, setCurrentDisplayedJobs] = useState([]);
  const entriesPerPage = 25;


  const fetchJobData = async () => {
    console.log(accessToken);
    try {
        const response = await axios.post(process.env.REACT_APP_TECH_SERVICE+'/history', {accessToken: accessToken });
        // const response = await axios.post('http://localhost:8085/history', {accessToken: accessToken });

      // Sort the job list by date and time in descending order
        const sortedJobs = response.data.sort((a, b) => {
        const dateA = new Date(`${a.date} ${a.time}`);
        const dateB = new Date(`${b.date} ${b.time}`);
        return dateB - dateA;
    });

    setJobList(sortedJobs);

    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleNextPage = () => {
    const totalPages = getTotalPages();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };


  useEffect(() => {
    fetchJobData();
  }, []);


  useEffect(() => {
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = Math.min(startIndex + entriesPerPage, jobList.length);
    const currentPageJobs = jobList.slice(startIndex, endIndex);
    setCurrentDisplayedJobs(currentPageJobs);
  }, [currentPage, jobList]);



  const renderJobList = () => {
    return currentDisplayedJobs.map((job) => (
      <tr key={job.id}>
        <td className="w-jid">
          <a className="job-ref" href={`/viewjobdetails/${job.id}`}>
            <text>{job.id}</text>
          </a>
        </td>
        <td className="w-sname">{job.serviceId}</td>
        <td className="w-cost">{job.cost}</td>
        <td className="w-date" data-field-type="date">{job.date}</td>
        <td className="w-time">{job.time}</td>
      </tr>
    ));

  };


  const getTotalPages = () => Math.ceil(jobList.length / entriesPerPage);

  const renderPaginationLinks = () => {
    const totalPages = getTotalPages();
    const currentPageIndex = currentPage - 1;
    const paginationLinks = Array.from({ length: totalPages }, (_, index) => index + 1);
    let startPageIndex = Math.max(currentPageIndex - 2, 0);
    let endPageIndex = Math.min(startPageIndex + 4, totalPages - 1);
  
    if (endPageIndex - startPageIndex < 4) 
    {
      endPageIndex = Math.min(startPageIndex + 4, totalPages - 1);
      startPageIndex = Math.max(endPageIndex - 4, 0);
    }
  
    const visiblePaginationLinks = paginationLinks.slice(startPageIndex, endPageIndex + 1);
    return visiblePaginationLinks.map((page) => (
      <li key={page}>
        <a
          className={currentPage === page ? 'pageLink disabled' : 'pageLink'}
          onClick={() => handlePageChange(page)}
        >{page}
        </a>
      </li>
    ));
  };
  
  

  return (

    <div>

      <Helmet>
        <title>User Summary</title>
      </Helmet>

      <div className="breadcrumb">
        <ol className="container breadcrumb">
            <li className="breadcrumb-item active admin-home">
                <a href="/" className='admin-home-text'> Home</a>
                 <a> / </a>
                <a href="/admin_users" className='admin-home-text'>User Management</a>
                <a> / </a>
                <a href="#" className='admin-home-text'>User Summary</a>
            </li>
        </ol>
    </div>

    <h2 className="user-summary-center-heading">User Statistics</h2>

      <div className="summary-container">
        <div className="summary-panel">
            <div className="summary-heading">Usage History</div>
            <div className="summary-panel-body">

                <table className="summary-table">
                  <thead>
                    <tr>
                      <th className="w-jid">Job ID</th>
                      <th className="w-sname">Service Name</th>
                      <th className="w-cost">Cost</th>
                      <th className="w-date">Date</th>
                      <th className="w-time">Time</th>
                    </tr>
                  </thead>
                  <tbody>{renderJobList()}</tbody>
                </table>

                <div className="form-group col-md-11 summary-pagination-centered">
                  <ul className="pagination">
                    <li> <a className="pageLink" onClick={() => handlePageChange(1)}> Newest </a> </li>
                    <li> <a className="pageLink disabled">.</a>  </li>
                    <li> <a className="pageLink disabled">.</a>  </li>
                    <li> <a href="#" onClick={handlePreviousPage}>&lt;</a> </li>
                    <li> <a className="pageLink disabled">.</a>  </li>
                    {renderPaginationLinks()}
                    <li> <a className="pageLink disabled">.</a> </li>
                    <li> <a href="#" onClick={handleNextPage}>&gt;</a> </li>
                    <li> <a className="pageLink disabled">.</a>  </li>
                    <li> <a className="pageLink disabled">.</a>  </li>
                    <li> <a className="pageLink" onClick={() => handlePageChange(getTotalPages())}> Oldest </a> </li>
                  </ul>
                </div>

            </div>
        </div>
      </div>

      
    </div>
  );
}


// export default ViewSummaryModel;

const SummaryWithAuth = withAuth(ViewSummaryModel);
export default SummaryWithAuth;
